<template>
	<div id="privacy">
		<div class="nybanner">
			<div class="index_1_ico">
				<img src="../assets/images/index/img3.png">
			</div>
			<div class="nybanner_bg">
			</div>
		</div>
		<div class="main">
			<div class="div1200">
				<div class="main_tab">
					<div class="tab_left wow fadeInUp">
						<el-breadcrumb separator="/">
							<el-breadcrumb-item class="breadcrumb1" :to="{ path: '/' }">Home</el-breadcrumb-item>
							<el-breadcrumb-item class="breadcrumb3">Privacy Policy</el-breadcrumb-item>
						</el-breadcrumb>
					</div>
					<div class="tab_right" @click="goRouter">
						<img src="../assets/images/news/img3.png" />
					</div>
				</div>
				<div class="privacyContent">
					<div class="title wow fadeInUp">
						Privacy Policy
					</div>
					<div class="content wow fadeInUp">
						<p>
							Thank you for visiting Canhelp Genomics' official website (hereinafter referred to as
							"the Site"). Canhelp Genomics and its affiliated companies (hereinafter collectively
							referred to as "the Company" or "we") is committed to protecting and safeguarding your
							privacy. This privacy policy ("Policy") applies to areas within People's Republic of
							China（“PRC”） and is governed by Chinese laws and regulations (such as the Network
							Security Law, the Data Security Law, the Personal Information Protection Law, etc.) and
							the requirements of our relationship with the person to whom it relates ("you"), the
							information we provide (hereinafter referred to as "you"), and the privacy practices we
							use. ), the products or services we offer (or seek to offer), details of how we collect,
							use, store, share, transfer, disclose and protect your personal information, and the
							ways in which we provide you with access to, copy, correct and delete such information.
							<br>
							Please read our terms and conditions, which are highlighted in <strong>bold</strong>, to
							ensure that you
							fully understand and agree to the rules governing the processing of your personal
							information before you start using this site. If you are redirected to this website by a
							third party or if you access a third party's website and use their services through this
							website, the commitments made in that third party's privacy policy are not relevant to this
							website. Please note that <strong>we have no control over the products or services offered
								by third
								parties or the personal information they process. The protection of your personal
								information in connection with your use of third party products or services does not
								apply
								to this policy. This policy also does not apply to any information that you choose to
								provide to third parties and you are advised to obtain details of their privacy policies
								by
								reviewing the privacy policies of, or contacting, such third parties. If you become
								aware of
								risks associated with web pages created by these third parties or applications developed
								by
								third parties, you are advised to discontinue such actions to protect your legitimate
								interests.</strong>
						</p>
						<p>
							<strong>[Collection and use of personal information]</strong><br>
							Depending on your relationship with us, you may be a visitor to this site, our customer,
							reseller, supplier, partner or other person who interacts with us. We may need to collect
							and use some of your personal information in order for you to use our products and/or
							services and to conduct normal business with us.<br>
							Please be aware and agree that we will only collect and use your personal information for
							the purposes described in this policy and where we have your authorized consent or other
							lawful basis to do so. We will use and protect your personal information lawfully in
							accordance with applicable laws and regulations and the provisions of this policy, and we
							will explain in detail below how we collect and use your personal information (where
							sensitive personal information is bolded and underlined).<br>
							<strong>
								Please note that we do not require your authorized consent for the collection and use of
								your personal information in the following circumstances.<br>
								(1) It is necessary for the formation and performance of a contract to which you are
								a party.<br>
								(2) necessary for the performance of our statutory obligations.<br>
								(3) In response to a public health emergency or when necessary to protect your life,
								health or property or that of others in an emergency.<br>
								(4) Handling of personal information within reasonable limits for the purpose of
								carrying out acts such as news reporting and public opinion monitoring in the public
								interest.<br>
								(5) Dispose of personal information that you disclose yourself or that has otherwise
								been lawfully disclosed, within reasonable limits, in accordance with the law.<br>
								(6) Other circumstances as stipulated by laws and administrative regulations.
							</strong>
						</p>
						<p>
							<strong>
								[Use of Cookies and Similar Technologies]
							</strong>
							<br>
							(i) Use of Cookies<br>
							To ensure that our website works properly and to personalize your experience, cookies, pixel
							tags and other identification and local storage tools (collectively, "Cookies") are used in
							our products or services to analyze trends, operate our website, and track user activity
							around the website. Cookies are plain text files that are stored on your computer or mobile
							device by a web server and whose contents can only be retrieved or read by the server that
							created them. Each cookie is unique to your web browser or mobile application; cookies
							usually contain an identifier, the site name and some numbers and characters. Cookies enable
							websites to store data such as user preferences to improve the user experience, and we do
							not use cookies for any purpose other than those described in this privacy policy. As with
							most websites, our website automatically collects certain information. This information may
							include Internet Protocol (IP) address, browser type, Internet Service Provider (ISP),
							referring/exit pages, files viewed on our site (e.g. HTML pages, graphics, etc.), operating
							system, date/time stamp and/or clickstream data to analyse aggregation trends and administer
							the site. In most cases, you can manage cookie preferences by adjusting the settings on your
							browser to deactivate cookies and other data collection techniques. Please note that if you
							choose to delete or reject cookies, or clear local storage, this may affect the
							functionality, usability and performance of this website.
						</p>
						<p>
							<strong>
								[Sharing of personal information]
							</strong><br>
							We take the protection of your personal information very seriously. Please be aware that we
							may share your personal information with our affiliates, partners, customers and other
							entities as necessary for normal business activities such as testing services and for
							purposes such as scientific research, product development and upgrades. In addition, for
							purposes such as commissioned testing, site verification, cloud services, technical support,
							etc., we may entrust all or part of their processing activities to third parties, as
							described in more detail below. We will take a range of reasonable measures to ensure that
							these types of third parties process your personal information legally, compliantly and
							securely, with appropriate security measures in place. If we share your personal information
							with a third party, we will inform you of this policy and obtain your consent or other
							lawful basis to do so. The aforementioned third parties will only process your personal
							information for the purposes set out in this policy, agree to process your personal
							information in accordance with this policy and relevant provisions of law and regulations,
							and will only process your personal information in the manner in which we are permitted to
							process your personal information.
							<br>
							<strong>
								Please note that we do not require your prior authorized consent to share your personal
								information in the following circumstances.
								<br>
								(1) It is necessary for the formation and performance of a contract to which you are
								a party.
								<br>
								(2) necessary for the performance of our statutory obligations.
								<br>
								(3) In response to a public health emergency or when necessary to protect your life,
								health or property or that of others in an emergency.
								<br>
								(4) Handling of personal information within reasonable limits for the purpose of
								carrying out acts such as news reporting and public opinion monitoring in the public
								interest.
								<br>
								(5) Dispose of personal information that you disclose yourself or that has otherwise
								been lawfully disclosed, within reasonable limits, in accordance with the law.
								<br>
								(6) Other circumstances as stipulated by laws and administrative regulations.
							</strong>
						</p>
						<p>
							<strong>
								[Storage and cross-border transmission of personal information]
							</strong>
							<br>We hold your personal information for as long as reasonably necessary for the purpose of
							your relationship with us and your use of our products or services. If you ask us to delete
							your personal information, we will stop using and delete your personal information or
							anonymise it in accordance with the applicable laws and regulations.<br>
							In principle, personal information that we collect and generate in the People's Republic of
							China will be stored in the People's Republic of China. At the same time, given that we may
							use resources and infrastructure from around the world to provide our services, there may be
							cross-border transfers of personal information (excluding personal information of
							examinees). When we transfer your personal information from the People's Republic of China
							to other countries or regions, we will strictly comply with Chinese laws and regulations and
							take a range of reasonable measures to ensure that the activities of the overseas recipient
							in handling your personal information meet the protection standards set out in Chinese laws
							and regulations at . We will comply with the relevant requirements of laws and regulations
							to conduct security assessments of personal information leaving the country, sign data
							transfer agreements, etc. in order to adequately prevent and control risks and safeguard the
							security of your personal information.
						</p>
						<p>
							<strong>
								[Security of personal information]
							</strong>
							<br>
							We have always placed great importance on protecting the security of your personal
							information, and to this end we have adopted industry-standard technical security measures
							as well as organizational and management measures (including ISO/IEC 27001 certification and
							network security level protection certification of our core information systems) to minimize
							the risk of disclosure, destruction, misuse, unauthorized access, unauthorized disclosure
							and alteration of your information. and alteration of your information. In the event of a
							personal information security incident, we will actively fulfil our obligation to inform
							you, which may include informing you of the following information: the basic circumstances
							of the security incident and its possible impact, the measures we have taken or will take to
							deal with it, and suggestions for you to prevent and mitigate the risk on your own. We will
							endeavour to keep you informed in a timely manner by telephone, email, push notifications
							and, in certain circumstances, we may post an announcement on our website or internal
							systems. If you know or have reason to believe that your personal information has been lost,
							stolen, misappropriated or otherwise compromised, or if there is any actual or suspected
							misappropriation of your personal information, you may contact us at the contact details
							published in this policy.
						</p>

						<p>
							<strong>[Rights of the subject of personal information]</strong><br>
							In accordance with applicable laws and regulations, you have some or all of the following
							rights in relation to your personal information and you can exercise your rights by
							contacting us at the contact details listed in [Contact Us].<br>
							1) You have the right to be informed and to make decisions about the processing of your
							personal information, and you have the right to restrict or refuse our processing of your
							personal information.<br>
							2) You have the right to access and copy your personal information.<br>
							3) If you discover that your personal information is inaccurate or incomplete, you have the
							right to request that we correct or supplement it.<br>
							4) You have the right to withdraw your consent to the processing of personal information
							based on your consent. If you withdraw your consent, this does not affect the validity of
							the personal data processing activities already carried out on the basis of your consent
							before the withdrawal.<br>
							5) you have the right to request the transfer of personal information to another processor
							of personal information designated by you.<br>
							6) you have the right to request an explanation of the relevant personal data processing
							rules.<br>
							7) You have the right to request that we delete your personal information in certain
							circumstances.<br>
							8) If you have any objections to the processing of your personal information by us and to
							this policy, you have the right to take legal action and other lawful means to resolve the
							matter.
						</p>
						<p>
							<strong>
								We will respond as soon as possible. It is important to note that we will not be able to
								respond to your request in the following circumstances.
							</strong><br>
							1) in connection with the performance of our obligations under laws and regulations.<br>
							2) directly related to national security and defence security.<br>
							3) directly related to public safety, public health or significant public interest.<br>
							4) directly related to criminal investigations, prosecutions, trials and enforcement of
							sentences.<br>
							5) where we have sufficient evidence of subjective malice or abuse of your rights.<br>
							6) where it is necessary to protect your life, property or other significant legitimate
							interests, or those of other individuals, but where it is difficult to obtain my authorized
							consent.<br>
							7) Where responding to your request would result in serious damage to the legitimate rights
							and interests of the subject of the personal information or other individuals or
							organizations.<br>
							8) Where trade secrets are involved.<br>
						</p>
						<p>
							<strong>
								[Handling of Children's Personal Information]
							</strong><br>
							The definition of a child in this policy will be determined in accordance with the
							applicable law, which defines a child as a minor under the age of fourteen years and whose
							personal information is sensitive in accordance with the relevant laws and regulations of
							the People's Republic of China.<br>
							We are generally intended for adults. <strong>If you are a child under applicable law, your
								guardian should read this policy and use our services or provide us with your personal
								information with the consent of your guardian, and where we collect your information
								with the consent of your guardian, we will only process your personal information as
								follows Where permitted by applicable law or regulation, or where the guardian has given
								express consent, or to protect the child's where the processing of the child's personal
								information is permitted by applicable laws and regulations, or where the guardian has
								given express consent, or where it is necessary to protect the child's personal
								information rights.</strong><br>
							If a child uses the services of this website and provides personal information without the
							consent of the guardian, the guardian has the right to contact us and refuse to process the
							personal information of the ward further or to request that the personal information of the
							ward be deleted from this website and we will seek to delete the relevant personal
							information as soon as possible.
						</p>
						<p>
							<strong>[Contact Us]</strong><br>
							If you wish to exercise any of the above rights, or if you have any questions or comments
							about this policy, your personal information or our personal information protection
							provisions, please contact us as follows and we will respond to your request within 15
							working days of receiving your feedback.<br>
							Contact address: 301M, D district, <span style="color: #00388a;">22 Xinyan Road, Hangzhou,
								311100, China</span><br>
							Contact: info@canhelpgenomics.com
						</p>
						<p>
							<strong>[Update of this policy]</strong>
							<br>
							In order to provide you with a better service and as our business grows,we will post an
							updated version of our online privacy policy on this site and alert you to updates via this
							site or other appropriate means before they become effective, and you are encouraged to
							visit this site to keep up to date with the latest privacy policy.<br>
							We will also provide more prominent notice of material changes (we may indicate specific
							changes to this policy by means including, but not limited to, email, text message or a
							special notice on a page of our website).<br>
							Material changes within the meaning of this policy include, but are not limited to.
							1) A significant change in our service model. such as the purposes for which personal
							information is processed, the types of personal information processed, and the manner in
							which personal information is used.<br>
							2) Significant changes in our ownership structure, organizational structure, etc. such as
							changes in ownership resulting from business restructuring, bankruptcy mergers and
							acquisitions, etc.<br>
							3) A change in the primary recipients of the sharing, transfer or public disclosure of
							personal information.<br>
							4) significant changes in your rights to participate in the processing of personal
							information and how they are exercised.<br>
							5) In the event of a change in the department responsible for handling the security of
							personal information, our contact details and the channels for making complaints.<br>
							6) When a personal information protection impact assessment report indicates a high
							risk.<br>
							We will also keep a historical version of this policy on file for your review.
						</p>
						<p>
							<strong>1. Visitors to this site</strong>
							<br>
							If you visit this site, you will be considered as a visitor to this site and this section
							applies to you.
							<br>
							<strong>1.1 </strong>Collection and use of personal information<br>
							To assist you in completing your application, we may collect personal information from
							visitors to this site so that we can view your CV and contact you in a timely manner,
							including: your interest in applying, your email address and your CV.<br>
							In order to get in touch with you, we may also collect personal information on this site to
							help us understand your needs and communicate with you about your research ideas and
							technical requirements, including: field of study, position, first name, surname,
							institution, country, postcode, email address, telephone number and your message.
							<br>
							<strong>1.2 </strong>Sharing of personal information
							<br>
							We will only share your personal information with the data recipients listed below with your
							authorized consent or where we have a lawful basis to do so. Depending on our relationship
							with visitors to our site, we may share your personal information with the following
							parties: We engage technology providers to assist us in operating our website. These
							technology providers analyze information about our site and your use of the site. We do not
							disclose personal information about visitors to this site to other third parties unless
							otherwise stated in this policy.
						</p>
						<p>
							<strong>2. customers and resellers</strong>
						<br>
							If you are a representative, contact person and other relevant person of a natural person,
							legal entity or other organization who has directly contracted with us to purchase our
							products or services, or if you are a natural person, legal entity or other organization who
							wants to purchase our products or services (a latent customer), then you are a customer of
							our company. If you are a natural person who directly purchases and uses our products or
							services (e.g. an examinee), we will process your personal information in accordance with
							applicable laws and regulations and after special ethical review to ensure that its
							processing activities are legal and compliant and that your privacy and rights are not
							violated. For other detailed rules on the processing of personal information, please see the
							privacy policy of the specific product or service concerned and the corresponding informed
							consent form.<br>
							If you are a natural person, legal entity or other organization who has a direct contract
							with us to sell or represent our products or services on our behalf, then you are a reseller
							of our company.<br>
							This section applies to our customers and resellers.
						<br>
							<strong>2.1 </strong>Collection and use of personal information
						<br>
							Depending on our relationship with our customers and resellers and the products or services
							we provide, we may collect and use certain personal information about customers and
							resellers or persons related to customers and resellers for business contacts and to conduct
							normal business activities. In relation to customers and resellers, this includes
							specifically the following personal information.
						</p>
						<p>
							<strong>Basic contact information.</strong>
						<br>
							If the customer and reseller is a legal person or other organization, we will collect basic
							information such as the name, address, contact details and <strong><u>legal ID number of the
									business
									or organization, and if you are a representative of the business or
									organization,</u></strong> we will
							collect information such as your name, contact details and your role in the business or
							organization in order to facilitate contact with you and promote cooperation. If the
							customer and reseller is a natural person, we will collect information such as your name and
							contact details so that we can contact you, confirm your identity (e.g. to enable sample
							binding) and provide you with our products or services.
						</p>
						<p>
							<strong>Information generated in the course of conducting business.</strong>
						<br>
						In the course of our business with our customers and resellers, the personal information of
							our customers and resellers includes information relating to contracts signed by you and the
							business or organization you represent, order information and payment information for
							payment purposes and communication information. We collect and use your personal information
							as described above only for the purposes of business cooperation.<br>
							We may collect your personal information through direct communication with you. In some
							cases, we may obtain your personal information from our affiliated companies or third
							parties, such as businesses related to you, partners, or natural or legal persons or other
							organizations entrusted by you to provide us with your personal information.<br>
						</p>
						<p><strong>2.2 </strong>Sharing of personal information<br>
							We will only share your personal information with the data recipients listed below if we
							have your authorized consent or other lawful basis to do so. We may provide all or part of
							your personal information to third parties for the purposes necessary for the performance of
							a contract, for the performance of a legal duty or for the performance of a legal
							obligation, among other basic purposes. We will take a range of reasonable steps to ensure
							that such third parties process your personal information lawfully, lawfully and securely,
							with appropriate security measures in place. Depending on our relationship with our
							customers and resellers, we may share your personal information with the following
							parties.<br>
							The legal entity or other organization you represent in relation to the client/reseller
							contact.<br>
							Other clients, resellers, suppliers, partners in relation to the implementation of the
							project.<br>
							Payment institutions such as banks.<br>
							A service provider offering cloud storage, technical support and support for day-to-day
							matters for the Company.<br>
							Lawyers, accountants etc. and other persons engaged by the Company to give professional
							advice.<br>
							Relevant government departments, regulatory, law enforcement, taxation and other agencies or
							industry bodies as defined by law and regulation.<br>
							Other third parties from whom we have obtained your prior consent or who have another legal
							basis.
						</p>
						<p><strong>3. Suppliers and partners</strong><br>
						This section applies to you if you are a representative, contact person and other relevant
							persons of a natural person, legal entity or organization that contracts directly with us
							and provides products and/or services, including providing us with system maintenance,
							logistics services, commissioned testing, site verification and other services, or
							cooperating with us in testing services, scientific research, product development, etc.,
							i.e. you are a supplier and partner of our company.
						</p>
						<p><strong>3.1 </strong>Collection and use of personal information<br>
						Depending on our relationship with our suppliers and partners and the products or services
							they provide to us, we may collect and use certain personal information from suppliers and
							partners or persons associated with suppliers and partners for business contacts and to
							assist suppliers and partners in providing the appropriate products or services to us. This
							includes, in particular, the following personal information.
						</p>
						<p>
							<strong>Basic contact information.</strong>
						<br>
							If the supplier or partner is a legal person or other organization, we will collect basic
							information such as the name, address, contact details and <strong><u>legal ID number of the
									company or</u> </strong> organization. If you are a representative of the company or
							organization, your company will provide us with your name, contact details and your role in
							the company or organization in order to facilitate contact and cooperation with you. If the
							supplier or partner is a natural person, we will collect your name and contact details so
							that we can contact you.
						</p>
						<p>
							<strong>Information generated in the course of conducting business.</strong>
						<br>
							In the course of our business with suppliers and partners, personal information about
							suppliers and partners includes information relating to contracts signed with you and the
							business or organization you represent, order information and payment information for
							payment purposes, and communication information. We collect and use your personal
							information as described above only for the purposes of business cooperation.<br>
							We may collect your personal information through direct communication with you. In some
							cases, we may obtain your personal information from our affiliated companies or third
							parties, such as businesses related to you, partners, or natural or legal persons or other
							organizations entrusted by you to provide us with your personal information.<br>
						</p>
						<p>
							<strong>3.2 </strong>Sharing of personal information
						<br>We will only share your personal information with the data recipients listed below if we have
							your authorized consent or other lawful basis to do so. We may provide all or part of your
							personal information to third parties for the purposes necessary for the performance of a
							contract, for the performance of a legal duty or for the performance of a legal obligation,
							among other basic purposes. We will take a range of reasonable steps to ensure that such
							third parties process your personal information lawfully, lawfully and securely, with
							appropriate security measures in place. Depending on our relationship with our suppliers and
							partners, we may share your personal information with the following parties.<br>
							In the case of supplier/partner contacts, the legal or other organization you represent.<br>
							Other clients, resellers, suppliers, partners in relation to the implementation of the
							project.<br>
							Payment institutions such as banks.<br>
							Service providers offering cloud storage, technical support and support for day-to-day
							matters for the Company.<br>
							Lawyers, accountants etc. and other persons engaged by the Company to give professional
							advice.<br>
							Relevant government departments, regulatory, law enforcement, taxation and other agencies or
							industry bodies as defined by law and regulation.<br>
							Other third parties from whom we have obtained your prior consent.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// 动画效果
	import WOW from 'wow.js'

	export default {
		data() {
			return {}
		},
		mounted() {
			let wow = new WOW({
				boxClass: "wow", // animated element css class (default is wow)
				animateClass: "animated", // animation css class (default is animated)
				offset: 0, // distance to the element when triggering the animation (default is 0)
				mobile: true, // trigger animations on mobile devices (default is true)
				live: true, // act on asynchronously loaded content (default is true)
				callback: function(box) {},
				scrollContainer: null, // optional scroll container selector, otherwise use window
			});
			wow.init();
			$(".nybanner_bg").removeClass('on');
			setTimeout(function(){
				$(".nybanner_bg").addClass('on');
			},10)
		},
		methods:{
			goRouter(){
				this.$router.go(-1)
			}
		}
	}
</script>

<style scoped lang="scss">
	@import '../assets/scss/privacy.scss';
</style>