<template>
	<div id="advisor">
		<!-- banner -->
		<div class="bannerCss">
			<div class="banner_ico">
				<img src="../assets/images/index/img3.png">
			</div>
			<div class="com_mid">
				<div class="nybanner_1 slideUpAnimation wow fadeInUp">
					Strategic Advisors
				</div>
			</div>
			<div class="com_mid_img">
				<img v-if="$store.getters.getMobile" src="../assets/images/mobile/advior.png">
				<img v-else src="../assets/images/advisor/banner.png">
			</div>
		</div>
		<div class="main">
			<div class="div1200">
				<div class="pro_1 wow fadeInUp">
					Our strategic advisors are individuals that have significant scientific and business backgrounds in
					oncology, molecular diagnostics and other areas related to Canhelp Genomics’ technology and business
					operation. They provide strategic input, insight and expertise in the diagnostics and oncology
					fields and also advise the Company on aspects in relation to product development, clinical studies
					and business development as well as providing broader industry input internationally.
				</div>
				<!-- pc端 -->
				<div class="serise wow fadeInUp">
					<div class="serise_detail" v-for="(item,index) in advisorData" :key="index+''">
						<div class="serise_detail_left">
							<img :src="item.img" />
							<p class="p1">
								{{item.name}}
							</p>
						</div>
						<div class="serise_detail_right">
							<p class="p2">
								{{item.text}}
							</p>
						</div>
					</div>
				</div>
				<!-- 移动端 -->
				<div class="seriseMobile wow fadeInUp">
					<div class="mySwiper-news">
						<div class="seriseMobile_detail" v-for="(item,index) in advisorData" :key="index+''">
							<div class="seriseMobile_detail_top">
								<img class="seriseMobile_detail_top_left" :src="item.img" />
								<div class="seriseMobile_detail_top_right">
									{{item.name}}
								</div>
							</div>
							<div class="seriseMobile_detail_bottom">
								{{item.text}}
							</div>
						</div>
						<!-- <swiper :modules="newsSwpiermodules" :breakpoints="newsSwpierOption.breakpoints"
							:pagination="newsSwpierOption.pagination" :scrollbar="{ draggable: true }">
							<swiper-slide  v-for="(item,index) in advisorData" :key="index+''">
								<div class="seriseMobile_detail">
									<div class="seriseMobile_detail_top">
										<div class="seriseMobile_detail_top_left">
											<img :src="item.img" />
										</div>
										<div class="seriseMobile_detail_top_right">
											{{item.name}} 
										</div>
									</div>
									<div class="seriseMobile_detail_bottom">
										{{item.text}}
									</div>
								</div>
							</swiper-slide>
						</swiper> -->
					</div>
					<div class="swiper-pagination swiper-pagination-new  wow fadeInUp" id="swiper-pagination-new"></div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	// 动画效果
	import WOW from 'wow.js'
	import 'wow.js/css/libs/animate.css';
	// Import Swiper Vue.js components
	import {
		Navigation,
		Pagination,
		Scrollbar,
		A11y
	} from 'swiper';
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue';

	// Import Swiper styles
	import 'swiper/css';
	import 'swiper/css/navigation';
	import 'swiper/css/pagination';
	import 'swiper/css/scrollbar';
	export default {
		components: {
			Swiper,
			SwiperSlide
		},
		data() {
			return {
				advisorData: [{
					name: 'Dr. Jan Groen',
					img: require('../assets/images/advisor/img1.png'),
					text: 'Dr. Jan Groen holds a PhD degree in Medical Microbiology from the Erasmus University Rotterdam in the Netherlands, a BSc in Clinical Laboratory Studies. Dr. Groen has published over 125 scientific papers and holds numerous awards and patents in the fields of biomarker discovery, cancer genetics and molecular diagnostics. With extensive management experience as VP of R&D, COO, and CEO in the global life sciences industry, Dr. Groen has focused on emerging technologies, product development, and commercialization. Previously, he was the CEO of MDxHealth, a Euronext-listed genomic diagnostics company that improves patient outcomes by reducing diagnostic ambiguity in urological cancers. MDxHealth’s genomic tests are setting new standards in prostate cancer diagnosis, where they have helped over 100,000 patients avoid unnecessary diagnostic procedures.'
				}, {
					name: 'Dr. Xiang Du',
					img: require('../assets/images/advisor/img2.png'),
					text: 'Dr. Xiang Du has held several prominent positions, including serving as the Director of the Pathology Department at Fudan University Shanghai Cancer Center, the Deputy Director of the Pathology Center at Fudan University, and the Deputy Director of the Colorectal Cancer Diagnosis and Treatment Center at Fudan University in China. He has also served as the Chairman of the Oncology Pathology Committee of the Chinese Anti-Cancer Association, the Chairman of the Pathology Committee of the Chinese Research Hospital Association, the Standing Committee Member of the Pathology Branch of the Chinese Medical Doctor Association, among other academic roles. Dr. Du has been engaged in clinical pathology diagnosis for over 20 years, specializing in the pathological diagnosis and differential diagnosis of tumors, with a particular expertise in the pathological diagnosis of gastrointestinal tumors. As a principal investigator, he has undertaken more than 10 national and municipal scientific research projects, including projects funded by the National Natural Science Foundation of China and major and key projects in basic research in Shanghai. He has published over 50 academic papers in domestic and international journals. '
				}],
				// news
				newsSwpiermodules: [Pagination],
				newsSwpierOption: {
					slidesPerView: 1,
					spaceBetween: 0,
					breakpoints: {
						1024: {
							slidesPerView: 2,
							spaceBetween: 0,
						},
					},
					pagination: {
						clickable: true,
						el: ".swiper-pagination-new",
						//type: "progressbar",
					},
				},
				swiperHeight: 0, //外部的高度
			}
		},
		mounted() {
			let wow = new WOW({
				boxClass: "wow", // animated element css class (default is wow)
				animateClass: "animated", // animation css class (default is animated)
				offset: 0, // distance to the element when triggering the animation (default is 0)
				mobile: true, // trigger animations on mobile devices (default is true)
				live: true, // act on asynchronously loaded content (default is true)
				callback: function(box) {},
				scrollContainer: null, // optional scroll container selector, otherwise use window
			});
			wow.init()
		},
		methods: {
			// 跳转外部链接
			blankLink(url) {
				var that = this;
				window.open(url, '_blank');
			},
		}
	}
</script>

<style scoped lang="scss">
	@import '../assets/scss/advisor.scss';

	@media screen and (max-width: 810px) {
		.serise {
			display: none;
		}

		.seriseMobile {
			display: block;
		}
	}

	@media screen and (min-width: 810px) {
		.serise {
			display: block;
		}

		.seriseMobile {
			display: none;
		}
	}
</style>