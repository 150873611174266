<template>
	<router-view></router-view>
	<!-- <Home></Home> -->
</template>

<script>
	// import Home from './pages/home.vue'
	export default {
		name: 'App',
		// components:{
		// 	Home
		// }
		mounted() {
			// console.log(document.referrer)
			this.getReferrer();
		},
		methods:{
			// 和百度统计的数据对比着分析下
			getReferrer() {
				var that = this;
				that.$api.post("/api/loading",{
					referer:document.referrer
				}).then(function(res) {
					console.log(res);
				}).catch(function(error) {})
			},
		}
	}
</script>

<style lang="scss">
</style>