<template>
	<div id="myFooter">
		<div class="com_mid">
			<div class="divAuto">
				<!-- <div class="footer_mid_1">
					<img src="../assets/images/footer/logo.png">
				</div> -->
				<!-- <div class="footer_mid_2">
					<ul>
						<li v-for="(item,index) in routes.filter(item=>!item.meta.hide)">
							<router-link :to="item.path">
								{{item.meta.menuName}}
							</router-link>
						</li>
						<li>Email: info@canhelpgenomics.com</li>
					</ul>
				</div> -->
				<div class="footer_mid_3">
					<!-- 链接 -->
					<div class="contactLink">
						<img @click="openfacebook" src="../assets/images/footer/facebookimg.png" />
						<img @click="opentwitter" src="../assets/images/footer/Ximg.png" />
						<img @click="openlinkedin" src="../assets/images/footer/linkedinimg.png" />
					</div>
					<ul>
						<li>
							<span>Copyright© 2023 Canhelp Genomics &nbsp;</span>
							<a target="_blank" href="https://beian.miit.gov.cn/">浙ICP备19003791号-1</a>
						</li>
						<li>
							<router-link to="Privacy">
								Privacy Policy
							</router-link>
						</li>
						<li>Contact Us: info@canhelpgenomics.com</li>
						<li>
							<a class="navp" @click="changeCH">中文</a>
						</li>
						<!-- <li>
							<img @click="openfacebook" @mouseover="facebookImg=facebookHoverUrl"
								@mouseout="facebookImg=facebookUrl" :src="facebookImg" />
						</li>
						<li>
							<img @click="opentwitter" @mouseover="twitterImg=twitterHoverUrl"
								@mouseout="twitterImg=twitterUrl" :src="twitterImg" />

						</li>
						<li>
							<img @click="openlinkedin" @mouseover="linkedinImg=linkedinHoverUrl"
								@mouseout="linkedinImg=linkedinUrl" :src="linkedinImg" />
						</li> -->
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import facebookUrl from "@/assets/images/footer/facebook.png";
	import facebookHoverUrl from "@/assets/images/footer/facebookHover.png";
	import twitterUrl from "@/assets/images/footer/twitter.png";
	import twitterHoverUrl from "@/assets/images/footer/twitterHover.png";
	import linkedinUrl from "@/assets/images/footer/linkedin.png";
	import linkedinHoverUrl from "@/assets/images/footer/linkedinHover.png";

	export default {
		data() {
			return {
				routes: [], //路由数组
				facebookImg: facebookUrl,
				facebookUrl: facebookUrl,
				facebookHoverUrl: facebookHoverUrl,
				twitterImg: twitterUrl,
				twitterUrl: twitterUrl,
				twitterHoverUrl: twitterHoverUrl,
				linkedinImg: linkedinUrl,
				linkedinUrl: linkedinUrl,
				linkedinHoverUrl: linkedinHoverUrl,
			}
		},
		watch: {
			// 监听路由变化
			$route(to, from) {
				var that = this;
				that.routerBg(that.$route);
			}
		},
		mounted() {
			var that = this;
			that.getRoutes();
			that.routerBg(that.$route); //跳转初始情况
		},
		methods: {
			// 跳转英文官网
			changeCH() {
				window.location.href = this.windowUrl;
			},
			openfacebook() {
				window.open("https://www.facebook.com/Canhelp.Genomics", "_blank")
			},
			opentwitter() {
				window.open("https://twitter.com/Canhelpgene", "_blank")
			},
			openlinkedin() {
				window.open("https://www.linkedin.com/company/Canhelp-Genomics", "_blank")
			},
			// 获取路由数组
			getRoutes() {
				var that = this;
				var arr = that.$router.options.routes;
				that.routes = arr[0].children;
			},
			// 根据当前路由修改背景
			routerBg(val) {
				if (val.name == 'About' || val.name == 'ProductsShow' || val.name == 'NewsShow') {
					$('#myFooter').addClass('footerBg');
				} else {
					$('#myFooter').removeClass('footerBg');
				}
			},
		}
	}
</script>

<style scoped lang="scss">
	@import '../assets/scss/footer.scss';

	.footerBg {
		background: #fff !important;
	}

	.navp {
		cursor: pointer;
	}
</style>