import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router/index.js'
import 'babel-polyfill';
import Promise from 'es6-promise';
Promise.polyfill();
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import './assets/scss/font.scss';
import configFile from './config/config';
import store from './store/index';
import 'video.js/dist/video-js.css';
import Vuex from 'vuex'


const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) { //图标使用
	app.component(key, component)
}
app.config.globalProperties.$api = configFile.api;
app.config.globalProperties.windowUrl = configFile.windowUrl;

// 添加百度统计
var _hmt = _hmt || [];
window._hmt = _hmt; // 修改为window 全局变量
(function() {
	var hm = document.createElement("script");
	hm.src =configFile.srcUrl;
	var s = document.getElementsByTagName("script")[0];
	s.parentNode.insertBefore(hm, s);
})();


app.use(ElementPlus, {
	locale: zhCn,
})
app.use(Vuex)
app.use(router)
app.use(store)
app.mount('#app')

// 跳转后返回顶部
router.afterEach((to, from, next) => {
	window.scrollTo(0, 0);
})

router.onError((error) => {
	console.log(error);
	const pattern = /Loading chunk (\d)+ failed/g;
	const isChunkLoadFailed = error.message.match(pattern);
	if (isChunkLoadFailed) {
		location.reload()
	}
});