import axios from 'axios';

var dev=2;//1时测试 2时正式

var windowUrl=dev==2?"https://www.canhelpdx.com":"https://www.canhelpgenomics.cn";
var srcUrl=dev==2?"https://hm.baidu.com/hm.js?f2200963ebaf268d6f79f6d278c4c830":"https://hm.baidu.com/hm.js?756a246dea7c67a2771ee8e8badc7c2e";
const config = {
	baseURL:dev==2?"https://www.canhelpgenomics.com":"https://v2.canhelpgenomics.cn",
	timeout: 60000,
	headers: {
		'Content-Type': 'application/json;charset=UTF-8',
	},
};

const api = axios.create(config);

// 添加请求拦截器
api.interceptors.request.use(function(config) {
	config.headers['Content-Type'] = 'application/json;charset=UTF-8';
	return config
}, function(error) {
	return Promise.reject(error)
});

// http response 拦截器
api.interceptors.response.use(
	response => {
		return response.data
	},
	//接口错误状态处理，也就是说无响应时的处理
	error => {
		return Promise.reject(error) // 返回接口返回的错误信息
	})

export default {
	api,
	windowUrl,
	srcUrl
};