<template>
	<div id="myNews">
		<!-- banner -->
		<div class="bannerCss">
			<div class="banner_ico">
				<img src="../assets/images/index/img3.png">
			</div>
			<div class="com_mid">
				<div class="nybanner_1 slideUpAnimation wow fadeInUp">
					News
				</div>
			</div>
			<div class="com_mid_img">
				<img v-if="$store.getters.getMobile" src="../assets/images/mobile/news.jpg">
				<img v-else src="../assets/images/news/banner.jpg">
			</div>
		</div>
		<!-- main -->
		<div id="main">
			<div class="div1200">
				<el-row class="pro_1_1 wow fadeInUp" @click="goNewDetail(topNew.id)">
					<el-col :xs="24" :sm="24" :md="12" class="pro_1_1_mid_left">
						<img v-if="topNew.skin" :src="`${topNew.skin}`">
						<img v-else src="../assets/images/news/img1.jpg">
					</el-col>
					<el-col :xs="24" :sm="24" :md="12" class="pro_1_1_mid_right">
						<div class="rightContent">
							<div class="pro_info_1">
								{{topNew.title}}
							</div>
							<div class="pro_info_2">
								{{topNew.summary}}
							</div>
							<div class="pro_info_3">
								Discover more
							</div>
						</div>
					</el-col>
				</el-row>
				<div class="mySwiper-news">
					<el-row :gutter="25">
						<el-col :xs="24" :sm="12" :md="8" v-for="(item,index) in dataNews" :key="index" class="wow fadeInUp" @click="goNewDetail(item.id)">
							<div class="news_mid">
								<div class="news_mid_1 daxie">
									<span>time</span><span>{{item.date}}</span>
								</div>
								<div class="news_mid_2">
									{{item.title}}
								</div>
								<div class="news_t">
									{{item.summary}}
								</div>
								<div class="news_mid_3">
									<span>Discover more</span>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
				<!-- 分页 -->
				<div class="myPage wow fadeInUp">
					<el-pagination background :pager-count="5" layout="prev, pager, next" :page-size="pageSize" :total="total" @current-change="handleCurrentChange"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// 动画效果
	import WOW from 'wow.js'
	import 'wow.js/css/libs/animate.css'
	export default {
		data() {
			return {
				newsData:[],
				pageIndex:1,
				pageSize:13,
				total:0,
				enNews:[], //总得新闻获取
				dataNews:[],//除去第一条新闻，剩余的
				topNew:{},//第一条新闻
			}
		},
		mounted() {
			let wow = new WOW({
				boxClass: "wow", // animated element css class (default is wow)
				animateClass: "animated", // animation css class (default is animated)
				offset: 0, // distance to the element when triggering the animation (default is 0)
				mobile: true, // trigger animations on mobile devices (default is true)
				live: true, // act on asynchronously loaded content (default is true)
				callback: function(box) {
				},
				scrollContainer: null, // optional scroll container selector, otherwise use window
			});
			wow.init()
			this.getNewsData();
		},
		methods:{
			// 分页--页码的切换
			handleCurrentChange(oldV) {
				var that = this;
				console.log(oldV)
				that.publicationsData = [];
				that.pageIndex = oldV;
				that.getNewsData();
			},
			getNewsData(){
				var that=this;
				that.enNews=[]; //总得新闻获取
				that.dataNews=[];//除去第一条新闻，剩余的
				that.topNew={};//第一条新闻
				var pIndex=that.pageIndex -1;
				that.$api.get("/api/engNews/list/"+pIndex+"/"+that.pageSize).then(function(res) {
					that.enNews = res.data.data;
					if(that.enNews && that.enNews.length>0){
						that.topNew=that.enNews[0];
						var arr=JSON.parse(JSON.stringify(that.enNews));
						that.dataNews=arr.splice(1);
					}
					// console.log(that.dataNews);
					that.total=res.data.totalCount;
					that.pageSize=res.data.countPerPage;
				}).catch(function(error) {})
			},
			// 去新闻详情
			goNewDetail(id){
				var that=this;
				that.$router.push({
					path: '/news_show',
					query: {
						id: id
					}
				});
			}
		}
	}
</script>

<style scoped lang="scss">
	@import '../assets/scss/news.scss';
</style>