<template>
	<div id="newsShow" v-loading="loading" :element-loading-svg="svg" class="custom-loading-svg"
		element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="rgba(122, 122, 122, 0.5)" style="width: 100%">
		<div class="nybanner">
			<div class="index_1_ico">
				<img src="../assets/images/index/img3.png">
			</div>
			<div class="nybanner_bg">
			</div>
		</div>
		<div class="main">
			<div class="div1200">
				<div class="main_tab">
					<div class="tab_left wow fadeInUp">
						<el-breadcrumb separator="/">
							<el-breadcrumb-item class="breadcrumb1" :to="{ path: '/' }">Home</el-breadcrumb-item>
							<el-breadcrumb-item class="breadcrumb2" :to="{ path: '/news' }">News</el-breadcrumb-item>
							<el-breadcrumb-item class="breadcrumb3">Company News</el-breadcrumb-item>
						</el-breadcrumb>
					</div>
					<div class="tab_right" @click="goNews">
						<img src="../assets/images/news/img3.png" />
					</div>
				</div>
				<div class="main_detail">
					<div class="new_title wow fadeInUp">
						<p>
							{{news.title}}
						</p>
						<span>
							Release time&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{news.date}}
						</span>
					</div>
					<div class="new_info wow fadeInUp">
						<div id="mynewsContent" v-html="news.content"></div>
					</div>
				</div>
				<div class="main_deriction wow fadeInUp">
					<div class="hi" v-if="prev">
						<span>Prev</span>
						<span @click="newsClick(prev.id)" class="textTitle">{{prev.title}}</span>
					</div>
					<div class="hi" v-if="next">
						<span>Next</span>
						<span @click="newsClick(next.id)" class="textTitle">{{next.title}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// 动画效果
	import WOW from 'wow.js'
	import 'wow.js/css/libs/animate.css'
	export default {
		name: "NewsShow",
		data() {
			return {
				news: "",
				next: "",
				prev: "",
				loading: false,
				svg: `
				<path class="path" d="
					  M 30 15
					  L 28 17
					  M 25.61 25.61
					  A 15 15, 0, 0, 1, 15 30
					  A 15 15, 0, 1, 1, 27.99 7.5
					  L 15 15
					" style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
				  `
			
			}
		},
		watch: {
			// 监听路由变化
			$route(to, from) {
				var that = this;
				window.scrollTo(0, 0);
				// 获取详情
				if (to.query && to.query.id) {
					that.loading=true;
					that.getDetail(to.query.id)
				}
			}
		},
		mounted() {
			var that = this;
			let wow = new WOW({
				boxClass: "wow", // animated element css class (default is wow)
				animateClass: "animated", // animation css class (default is animated)
				offset: 0, // distance to the element when triggering the animation (default is 0)
				mobile: true, // trigger animations on mobile devices (default is true)
				live: true, // act on asynchronously loaded content (default is true)
				callback: function(box) {},
				scrollContainer: null, // optional scroll container selector, otherwise use window
			});
			wow.init();
			if (that.$route.query && that.$route.query.id) {
				that.loading=true;
				that.getDetail(that.$route.query.id)
			}
			$(".nybanner_bg").removeClass('on');
			setTimeout(function() {
				$(".nybanner_bg").addClass('on');
			}, 10)
		},
		methods: {
			// 获取新闻详情
			getDetail(id) {
				var that = this;
				that.$api.get("/api/engNews/detail/" + id).then(function(res) {
					that.news = res.data.news ? res.data.news : "";
					that.next = res.data.next ? res.data.next : "";
					that.prev = res.data.prev ? res.data.prev : "";
					that.loading=false;
				}).catch(function(error) {
					that.loading=false;
				})
			},
			newsClick(id) {
				var that = this;
				that.news = "";
				that.next = "";
				that.prev = "";
				that.$router.push({
					path: '/news_show',
					query: {
						id: id
					}
				});
			},
			goNews() {
				var that = this;
				that.$router.push('/news');
			}
		}
	}
</script>

<style scoped lang="scss">
	@import '../assets/scss/news_show.scss';
	
</style>