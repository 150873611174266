<template>
	<div id="publications">
		<!-- banner -->
		<div class="bannerCss">
			<div class="banner_ico">
				<img src="../assets/images/index/img3.png">
			</div>
			<div class="com_mid">
				<div class="nybanner_1 slideUpAnimation wow fadeInUp">
					Publications
				</div>
			</div>
			<div class="com_mid_img">
				<img v-if="$store.getters.getMobile" src="../assets/images/mobile/popline.jpg">
				<img v-else src="../assets/images/pub/banner.jpg">
			</div>
		</div>
		<!-- main -->
		<div id="main">
			<div class="div1200">
				<div class="main_tab">
					<div class="pub_1_1_left wow fadeInUp">
						RELATED PRODUCT
					</div>
					<div class="pub_1_1_right">
						<ul>
							<li @click="tabClick('')" :class="[!productId?'liActive':'']">
								All
							</li>
							<li v-for="(item,index) in productTab" :key="index+''" @click="tabClick(item.id)" :class="[productId==item.id?'liActive':'']">
								{{item.name}}
							</li>
						</ul>
					</div>
				</div>
				<div class="main_content" v-if="publicationsData && publicationsData.length>0">
					<el-row>
						<el-col :xs="24" :sm="24" class="mycol wow fadeInUp" v-for="(item,index) in publicationsData" :key="index" @click="blankLink(item.link)">
							<div class="colDiv">
								<div class="col_left">
									<div class="col_left_1">
										{{item.title}}
									</div>
									<div class="col_left_2">
										{{item.authors}}
									</div>
									<div class="col_left_3">
										<span>{{item.journal}}</span> &nbsp;<span>{{item.volumePages}}</span>
									</div>
								</div>
								<div class="col_right">
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
				<!-- 分页 -->
				<div class="myPage wow fadeInUp" v-if="publicationsData && publicationsData.length>0">
					<el-pagination background :pager-count="5" layout="prev, pager, next" :current-page="currentPage" :page-size="pageSize" :total="total" @current-change="handleCurrentChange"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// 动画效果
	import WOW from 'wow.js'
	import 'wow.js/css/libs/animate.css'
	export default {
		data() {
			return {
				publicationsData:[],
				currentPage:1,
				pageIndex:1,
				pageSize:10,
				total:0,
				productTab:[],
				productId:""
			}
		},
		mounted() {
			var that=this;
			let wow = new WOW({
				boxClass: "wow", // animated element css class (default is wow)
				animateClass: "animated", // animation css class (default is animated)
				offset: 0, // distance to the element when triggering the animation (default is 0)
				mobile: true, // trigger animations on mobile devices (default is true)
				live: true, // act on asynchronously loaded content (default is true)
				callback: function(box) {
				},
				scrollContainer: null, // optional scroll container selector, otherwise use window
			});
			wow.init()
			that.getProductTab();
			that.getPublicationsData();
		},
		methods:{
			// 分页--页码的切换
			handleCurrentChange(oldV) {
				var that = this;
				that.currentPage=oldV;
				that.pageIndex = oldV;
				that.getPublicationsData();
			},
			// 获取数据
			getPublicationsData(){
				var that=this;
				that.publicationsData=[];
				var pIndex=that.pageIndex -1;
				that.$api({
					method:"post",
					data:{
						pageIndex: pIndex,
						productId: that.productId
					},
					url:"/api/publications/list",
				}).then(function(res) {
					that.publicationsData = res.data.data;
					that.total=res.data.totalCount;
					that.pageSize=res.data.countPerPage;
				}).catch(function(error) {})
			},
			// 获取文献标签
			getProductTab(){
				var that=this;
				that.productTab=[];
				that.$api.get("/api/publications/product/list").then(function(res) {
					that.productTab = res.data;
				}).catch(function(error) {})
			},
			// 跳转外部链接
			blankLink(url){
				var that=this;
				window.open(url, '_blank');
			},
			// tab搜索
			tabClick(val){
				var that=this;
				that.productId=val?val:'';
				that.pageIndex=1;
				that.currentPage=1;
				that.getPublicationsData();
			}
		}
	}
</script>

<style scoped lang="scss">
	@import '../assets/scss/publications.scss';
</style>