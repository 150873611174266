<template>
	<div id="myMenu">
		<!-- 屏幕尺寸大于950px时横向 horizontal,<=950px时纵向vertical -->
		<div class="menu1" v-if="((!menuShow) && verticalClose) || menuShow">
			<!-- :default-openeds="openeds" 默认打开二级菜单 -->
			<el-menu :mode="mode" :default-active="activeIndex" :ellipsis='false' class="el-menu-demo"
				@select="handleSelect" unique-opened>
				<template v-for="(item, index) in menuData">
					<!-- :popper-offset='9'  窗口偏移-->
					<el-sub-menu v-if="item.meta.childrenArray" :index="item.meta.menuId" :key="index+''"
						:teleported="false" :class="activeIndex==item.meta.menuId? 'actived':''" class="firstItem" :show-timeout="0"
						:hide-timeout="0">
						<template #title>
							<span style="width: 50%; text-align: left;" @click.native.stop="handleSelectMenu($event,item)">
								{{item.meta.menuName}}
							</span>
						</template>
						<template v-for="(subItem, subIndex) in item.meta.childrenArray">
							<el-menu-item v-if="subItem.menuId" :index="subItem.menuId" :key="subIndex+''">
								<template #title>{{subItem.menuName}}</template>
							</el-menu-item>
						</template>
					</el-sub-menu>
					<el-menu-item v-else-if="!item.meta.hide" :index="item.meta.menuId" :key="index" class="firstItem">
						<template #title>{{item.meta.menuName}}</template>
					</el-menu-item>
				</template>
			</el-menu>
			<!-- 中英文切换 -->
			<!-- <div class="languageChange" v-if="menuShow">
				<p class="navp" @click="changeCH">中文</p>
			</div> -->
		</div>
		<!-- 纵向菜单情况下的图标 -->
		<div class="verticalImg" v-if="!menuShow">
			<!-- 中英文切换 -->
			<!-- <p class="navp" @click="changeCH" v-if="!verticalClose">中文</p> -->
			<div class="uio" @click="changeStatus">
				<el-icon v-if="verticalClose">
					<Close />
				</el-icon>
				<div class="nav-btn" v-else>
					<div class="lindiv">
						<span class="line line1"></span>
						<span class="line line2"></span>
						<span class="line line3"></span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				mode: null, //菜单横向还是纵向判断
				activeIndex: '2', //默认菜单界面
				routes: [], //路由数组
				menuData: [], //菜单 
				openeds: ['2', '8'], //默认打开的菜单界面
				screenWidth: null,
				menuShow: false, // 菜单展示情况 true为展示
				verticalClose: false,
				menuProducts: []
			}
		},
		mounted() {
			var that = this;
			that.routerMethods(that.$route); //跳转初始情况
			that.getRoutes(); //获取routes中路由
			that.getScreenWidth(); // 获取当前屏幕尺寸
			that.productsLight(); //产品路由二级菜单
		},
		watch: {
			// 监听屏幕宽度变化
			screenWidth: function(n, o) {
				var that = this;
				if (n > 950) {
					that.menuShow = true;
					that.mode = 'horizontal';
				} else {
					that.menuShow = false;
					that.mode = 'vertical';
				}
				if(n <= 810){
					that.$store.commit("updateMobile",true);
				}
				else{
					that.$store.commit("updateMobile",false);
				}
			},
			// 监听路由变化
			$route(to, from) {
				var that = this;
				that.routerMethods(that.$route);
			}
		},
		methods: {
			// 跳转英文官网
			// changeCH(){
			// 	window.location.href=this.windowUrl;
			// },
			// 动态获取产品二级菜单添加至顶部
			productsLight() {
				var that = this;
				that.$api.get("/api/product/list/light").then(function(res) {
					var arr = res.data;
					arr.forEach((item) => {
						let obj = {
							menuId: 2 + '_' + item.id,
							menuName: item.name
						}
						that.menuProducts.push(obj)
					})
					that.routes.forEach((item) => {
						if (item.meta.menuId == 2) {
							item.meta.childrenArray = that.menuProducts
						}
					})
					that.menuData = JSON.parse(JSON.stringify(that.routes));
				}).catch(function(error) {})
			},
			// 刷新初始情况下
			routerMethods(val) {
				var that = this;
				$('.com_mid_img').removeClass("bannerOn");
				var timerNull=setTimeout(function(){
					$('.com_mid_img').addClass("bannerOn");
				},10);
				if (val.query.id && val.meta.hide) {
					if (val.name == "ProductsShow") {
						that.activeIndex = "2_" + val.query.id;
					} 
					else if (val.name == "About") {
						that.activeIndex = "8_" + val.query.id;
					}
					else if (val.name == "NewsShow") {
						that.activeIndex = "5";
					}
				} else {
					that.activeIndex = val.meta.menuId;
				}
			},
			// 获取当前屏幕尺寸
			getScreenWidth() {
				var that = this;
				that.screenWidth = document.body.clientWidth;
				window.onresize = () => {
					return (() => {
						that.screenWidth = document.body.clientWidth;
					})()
				}
			},
			// 获取路由数组
			getRoutes() {
				var that = this;
				var arr = that.$router.options.routes;
				that.routes = arr[0].children;
			},
			// 点击菜单选中 跳转
			handleSelect(key, keyPath) {
				event.stopPropagation(); //阻止默认事件的发生
				var that = this;
				// 匹配key是否为2_和8_打头二级菜单
				var isSubId = key.indexOf('_');
				if (isSubId > -1) {
					var arr = key.split("_");
					if (arr[0] == 2) {
						that.$router.push({
							path: '/products_show',
							query: {
								id: arr[1]
							}
						});
					} else if (arr[0] == 8) {
						that.$router.push({
							path: '/about',
							query: {
								id: arr[1]
							}
						});
					}
					that.activeIndex = key;
				} else {
					for (var i = 0; i < that.routes.length; i++) {
						if (key == that.routes[i].meta.menuId) {
							that.$router.push(that.routes[i].path);
							that.activeIndex = that.routes[i].meta.menuId;
						}
					}
				}
				// 当前纵向菜单打开情况下,则关闭
				if (that.verticalClose) {
					that.verticalClose = false;
				}
			},
			// 点击一级菜单
			handleSelectMenu(e, val) {
				var that = this;
				that.$router.push(val.path);
				that.activeIndex = val.meta.menuId;
				if(that.mode == 'vertical'){
					that.verticalClose = false;
				}
			},
			// 切换纵向情况下状态
			changeStatus() {
				var that = this;
				that.verticalClose = !that.verticalClose;
			}
		}
	}
</script>

<style scoped lang="scss">
	@import '../assets/scss/menu.scss';
</style>