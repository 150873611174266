<template>
	<div id="myIndex">
		<!-- banner -->
		<div class="index_1">
			<div class="index_1_ico">
				<img src="../assets/images/index/img3.png" />
			</div>
			<div class="index-container">
				<div class="com_mid_div">
					<div class="com_mid divAuto">
						<!-- slideUpAnimation -->
						<div class="banner_1 css3 wow fadeInUp">
							<img src="../assets/images/index/img1.png" />
						</div>
						<div class="banner_2 wow fadeInUp">
							Helping Cancer Patients
						</div>
						<div class="banner_3 wow fadeInUp">
							<router-link to="/about" class="dis_more">
								<span>Discover more</span>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- products -->
		<div class="index_2">
			<div class="com_mid divAuto">
				<div class="com_t slideUpAnimation">
					<div class="com_t_1 wow fadeInUp">
						products
					</div>
					<div class="com_t_2 wow fadeInUp">
						Precision Diagnostics for Personalized Medicine
					</div>
				</div>
				<div class="index_2_1 wow fadeInUp">
					<div class="mySwiper-product">
						<swiper :modules="productsSwpiermodules" :breakpoints="productsSwpierOption.breakpoints"
							:scrollbar="productsSwpierOption.scrollbar" :pagination="productsSwpierOption.pagination">
							<swiper-slide v-for="(item,index) in indexProducts" :key="index+''">
								<router-link :to="{path: '/products_show', query:{id:item.id}}"
									class="mySwiper-product-a">
									<div class="index_2_1_mid">
										<div class="mid_bg"></div>
										<div class="mid_jj">
											<div class="mid_jj_1">
												<span>
													<img class="img1" :src="`${item.icon}`">
													<img class="img2" :src="`${item.iconWhite}`">
												</span>
											</div>
											<div class="mid_jj_2 daxie">
												{{item.name}}
											</div>
											<div class="mid_jj_3">
												<!-- {{item.info}} -->
												<span v-html="item.info"></span>
											</div>
											<div class="mid_jj_4 dis_more">
												<p><span>Discover more</span></p>
											</div>
										</div>
									</div>
								</router-link>
							</swiper-slide>
						</swiper>
					</div>
					<div class="swiper-scrollbar" id="indexpro_scrollbar"></div>
					<div class="swiper-pagination swiper-pagination-product" id="swiper-pagination-product"></div>
				</div>
			</div>
		</div>
		<!-- products video -->
		<div class="index_3">
			<div class="index_3_bg">
			</div>
			<div class="com_mid divAuto">
				<div class="daxie com_t_1 com_t_1_white  wow fadeInUp">
					Canhelp-Origin
				</div>
				<div class="com_t_2 wow fadeInUp">
				</div>
				<div class="index_3_1 wow fadeInUp">
					<a href="javascript:;" @click="openVideo">
					</a>
				</div>
			</div>
		</div>
		<!-- Technology -->
		<div class="index_4">
			<div class="index_4_img">
				<img src="../assets/images/index/img11.jpg">
			</div>
			<el-row class="divAuto">
				<el-col :xs="24" :sm="12">
				</el-col>
				<el-col :xs="24" :sm="12" :md="{span:'4-8'}">
					<div class="index_4_mid">
						<div class="com_t_1 wow fadeInUp">
							Technology
						</div>
						<div class="com_t_2 wow fadeInUp">
							Dedicated to research and development of innovative molecular diagnostic technology, Canhelp
							Genomics’ purpose is to improve cancer patient care by helping doctors to resolve diagnostic
							uncertainty, with evidence that is trustworthy and actionable.
						</div>
						<div class="com_t_2 index_4_mid_right_1 wow fadeInUp">
							Our technology focus on mRNA biomarkers of all cancer types and Artificial Intelligence for
							data analysis of the combination of genomics, clinical science and bioinformatics, in order
							to generate insights that can help on accurate diagnosis of cancers and enable physicians to
							match patients to more personalized treatments.
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<!-- Publications -->
		<div class="index_5">
			<el-row>
				<el-col :xs="24" :sm="0">
					<img src="../assets/images/index/img13.jpg" />
				</el-col>
				<el-col :xs="24" :sm="12" class="index_5_left">
					<div class="cell_mid divAutoPdding">
						<div class="com_t_1 com_t_1_white wow fadeInUp">
							Publications
						</div>
						<div class="com_t_2 wow fadeInUp">
							Over the years, our scientists have been committed to Research and Clinical
							Application of tumor biomarkers, and cooperating in depth with researchers and
							clinicians in various clinical fields all over the world. Since 2011, more than
							80 research papers has published in international journals, such as
							Gastroenterology, Cell Research, Hepatology, Cancer Research, and Clinical
							Cancer Research.
						</div>
						<div class="left_mid">
							<router-link to="/publications" class="dis_more wow fadeInUp">
								<span>Discover more</span>
							</router-link>
						</div>
					</div>
				</el-col>
				<el-col :xs="0" :sm="12">
					<img class="publicationsImg" src="../assets/images/index/img13.jpg" />
				</el-col>
			</el-row>
		</div>
		<!-- news -->
		<div class="index_6">
			<div class="com_mid">
				<div class="com_t">
					<div class="com_t_1 wow fadeInUp">
						news
					</div>
					<div class="com_t_2">
					</div>
				</div>
				<div class="wow fadeInUp">
					<div class="mySwiper-news divAuto">
						<!--  :options="newsSwpierOption" :breakpoints="newsSwpierOption.breakpoints" :pagination="newsSwpierOption.pagination" -->
						<swiper :modules="newsSwpiermodules" :breakpoints="newsSwpierOption.breakpoints"
							:pagination="newsSwpierOption.pagination" :scrollbar="{ draggable: true }">
							<swiper-slide v-for="(item,index) in enNews" :key="index+''">
								<router-link :to="{path: '/news_show', query:{id:item.id}}">
									<div class="index_6_2_mid">
										<div class="mid_jj">
											<div class="mid_jj_1 daxie">
												<span>time</span><span>{{item.date}}</span>
											</div>
											<div class="mid_jj_2">
												{{item.title}}
											</div>
											<div class="mid_jj_3">
												<p>
													<span>Discover more</span>
												</p>
											</div>
										</div>
									</div>
								</router-link>
							</swiper-slide>
						</swiper>
					</div>
					<div class="swiper-pagination swiper-pagination-new  wow fadeInUp" id="swiper-pagination-new"></div>
				</div>
			</div>
		</div>
		<!-- products video -->
		<el-dialog v-model="videoVisible" :close-on-click-modal="false" :before-close="videoClose">
			<!-- 关闭画中画功能 disablePictureInPicture -->
			<video v-if="videoVisible" id="my-video" ref="media" class="myvideo video-js vjs-default-skin" autoplay
				controls preload="auto" style="width: 100%;height: auto;position: relative !important"
				controlslist="nodownload" disablePictureInPicture>
				<source :src="attachmentLink" type="application/x-mpegURL" />
			</video>
		</el-dialog>
	</div>
</template>s

<script>
	// 动画效果
	import WOW from 'wow.js'
	import 'wow.js/css/libs/animate.css'
	// import Swiper core and required modules
	// 视频播放m3u8
	import videojs from 'video.js';
	import 'videojs-contrib-hls';
	import "@videojs/http-streaming";
	import {
		Navigation,
		Pagination,
		Scrollbar,
		A11y
	} from 'swiper';

	// Import Swiper Vue.js components
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue';

	// Import Swiper styles
	import 'swiper/css';
	import 'swiper/css/navigation';
	import 'swiper/css/pagination';
	import 'swiper/css/scrollbar';
	// 引入本地json文件
	// import jsonData from '../assets/json/data.json';

	export default {
		name: 'Index',
		components: {
			Swiper,
			SwiperSlide
		},
		data() {
			return {
				// products  ,
				productsSwpiermodules: [Scrollbar, Pagination],
				productsSwpierOption: {
					slidesPerView: 1,
					spaceBetween: 15,
					breakpoints: {
						1024: {
							slidesPerView: 4,
							spaceBetween: 15,
						},
						768: {
							slidesPerView: 3,
							spaceBetween: 15,
						},
						480: {
							slidesPerView: 2,
							spaceBetween: 15,
						},
					},
					scrollbar: {
						el: "#indexpro_scrollbar",
						hide: false,
						draggable: true,
					},
					pagination: {
						clickable: true,
						el: ".swiper-pagination-product",
						//type: "progressbar",
					},
				},
				videoVisible: false,
				// news
				newsSwpiermodules: [Pagination],
				newsSwpierOption: {
					slidesPerView: 1,
					spaceBetween: 0,
					breakpoints: {
						1024: {
							slidesPerView: 4,
							spaceBetween: 0,
						},
						768: {
							slidesPerView: 3,
							spaceBetween: 0,
						},
						480: {
							slidesPerView: 2,
							spaceBetween: 0,
						},
					},
					pagination: {
						clickable: true,
						el: ".swiper-pagination-new",
						//type: "progressbar",
					},
				},
				swiperHeight: 0, //外部的高度
				// 新闻
				enNews: [],
				indexProducts: [],
				// video
				video: "",
				attachmentLink: "/video/canhelp.m3u8",
				buttonPicture: "",
				player: "",
				mountVideo:false,// true证明已经加载过
			}
		},
		// 离开页面销毁视频播放器 // dispose()会直接删除Dom元素
		// beforeRouteLeave (to, from, next) {
		// 	console.log(from)
		// 	if (this.player) {
		// 		this.player.dispose()
		// 	}
		//     next()   
		// },
		mounted() {
			var that = this;
			let wow = new WOW({
				boxClass: "wow", // animated element css class (default is wow)
				animateClass: "animated", // animation css class (default is animated)
				offset: 0, // distance to the element when triggering the animation (default is 0)
				mobile: true, // trigger animations on mobile devices (default is true)
				live: true, // act on asynchronously loaded content (default is true)
				callback: function(box) {},
				scrollContainer: null, // optional scroll container selector, otherwise use window
			});
			wow.init()
			that.getEnNews(); //获取新闻信息
			that.productsLight(); //获取产品信息
			// 滚动界面,顶部添加背景
			that.$nextTick(function() {
				window.addEventListener('scroll', that.scrollHander);
			})
		},
		methods: {
			scrollHander() {
				var that = this;
				var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				if (scrollTop > 5) {
					$(".banner_1").addClass('bannerLogoHidden');
				} else {
					$(".banner_1").removeClass('bannerLogoHidden');
				}
			},
			// 播放器初始化
			videoInit() {
				// console.log("初始化video")
				this.$nextTick(() => {
					this.player = videojs('my-video', {
						bigPlayButton: true,
						textTrackDisplay: false,
						posterImage: true,
						errorDisplay: false,
						controlBar: true,
						muted: true //静音模式 、、 解决首次页面加载播放。
					}, function() {
						this.play() // 自动播放
					})
				})
			},
			//pause() dispose()销毁dom  play() 
			getVideo() {
				var myVideo = videojs('my-video', {
					bigPlayButton: true,
					textTrackDisplay: false,
					posterImage: false,
					errorDisplay: false,
				})
				return myVideo;
			},
			// 关闭弹框
			videoClose() {
				var that = this;
				if (!that.$store.getters.getVideo) {
					that.player.pause();
				}
				that.player.dispose();
				that.videoVisible = false;
			},
			// video
			openVideo() {
				var that = this;
				that.videoVisible = true;
				// 当前画中画打开状态
				// if(!that.$store.getters.getVideo){
				// 	that.mountVideo=true;
				// }
				// else{
				// 	document.exitPictureInPicture();
				// 	that.$store.commit('updateVideo', false);
				// 	//未切换界面
				// 	if(that.mountVideo){  
				// 		that.player.dispose();
				// 		that.mountVideo=true;
						
				// 	}
				// }
				// if (that.mountVideo) {
				// 	that.videoInit();
				// }
			   // 去掉画中画功能
				that.videoInit();
				that.mountVideo=true;
				that.$nextTick(function() {
					that.video = document.getElementById("my-video");
					// 点击画中画
					that.video.addEventListener('enterpictureinpicture', function() {
						that.videoVisible = false;
						that.$store.commit('updateVideo', true);
					});
					// 返回标签页  并暂停
					that.video.addEventListener('leavepictureinpicture', function() {
						that.player.pause();
						that.$store.commit('updateVideo', false);
					});
				})
			},
			getEnNews() {
				var that = this;
				that.$api.get("/api/engNews/newsTop3").then(function(res) {
					that.enNews = res.data;
				}).catch(function(error) {})
			},
			productsLight() {
				var that = this;
				that.$api.get("/api/product/list/light").then(function(res) {
					that.indexProducts = res.data;
				}).catch(function(error) {})
			},

		}
	}
</script>

<style scoped lang="scss">
	@import '../assets/scss/index.scss';

	.mySwiper-news {
		:deep(.swiper) {
			.mid_jj_2 {
				height: 200px;
			}
		}
	}

	// 去掉画中画功能
	:deep(.vjs-picture-in-picture-control) {
		display: none !important;
	}

	@media only screen and (min-width:1024px) {
		.el-col-md-4-8 {
			width: 41% !important;
			flex: 0 0 41% !important;
		}
	}

	@media screen and (max-width: 810px) {
		.index_5 {
			.cell_mid {
				display: block !important;
				text-align: left !important;
				margin: 1.5em 1em;
			}
		}
	}
</style>

<!-- <style src="wow.js/css/libs/animate.css"></style> -->