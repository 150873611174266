<template>
	<div id="about">
		<!-- banner -->
		<div class="bannerCss">
			<div class="banner_ico">
				<img src="../assets/images/index/img3.png">
			</div>
			<div class="com_mid">
				<div class="nybanner_1 slideUpAnimation wow fadeInUp">
					ABOUT
				</div>
			</div>
			<div class="com_mid_img">
				<img v-if="$store.getters.getMobile" src="../assets/images/mobile/about.jpg">
				<img v-else src="../assets/images/about/banner.jpg">
			</div>
		</div>
		<!-- main -->
		<div class="main">
			<div class="aboutUs" ref="aboutUs" id="aboutUs">
				<div class="div1200">
					<div class="title wow fadeInUp">
						ABOUT US
					</div>
					<div class="aboutUs_content wow fadeInUp">
						<p>
							<img src="../assets/images/about/img1.png">
						</p>
						<p>
							Canhelp Genomics is a leading healthcare company focused on molecular diagnostics in the
							field
							of oncology. <br><br>
							Dedicated to research and development of innovative molecular diagnostic technology, Canhelp
							Genomics’ purpose is to improve cancer patient care by helping doctors to resolve diagnostic
							uncertainty, with evidence that is trustworthy and actionable. <br><br>
							Canhelp Genomics products uniquely combine genomic technology, clinical science and
							bioinformatics to generate insights that can help on accurate diagnosis of cancers and
							enable
							physicians to match patients to more personalized treatments. <br><br>
							With approximately 3~5% of all newly diagnosed cancers in the world presenting unknown
							primary
							site (CUP), our first NMPA（Chinese FDA）and CE approved product - Canhelp-Origin can
							accurately
							and quickly identify the tissue of tumor origin, and therefore help physicians to select
							optimal
							treatment for cancer patients.<br><br>
							We believe that our CCK (Canhelp Cancer Knowledge-base) AI Platform, with massive
							bioinformatic
							data and cutting-edge disease-gene association AI algorithm, empowers Canhelp Genomics with
							a
							key competitive advantage in the field of molecular diagnostics. In addition to
							Canhelp-Origin
							for metastatic tumors, we have a series of genomic products being validated or going through
							medical instrument registration process. All of these genomic tests were developed to
							address
							important unmet clinical needs in oncology, and aim to avoid unnecessary biopsies and
							unprecise
							treatments for cancer patients.<br><br>
							Canhelp Genomics is headquartered in the Joinstar Biomedical Valley, Hangzhou, China. We
							have
							our own certified molecular biology laboratory and a 1500
							<span>㎡</span> diagnostic reagent GMP factory.
						</p>
					</div>
				</div>
			</div>
			<!-- OUR HISTORY -->
			<div class="ourHistory" ref="ourHistory" id="ourHistory">
				<div class="div1200">
					<div class="title wow fadeInUp">
						OUR HISTORY
					</div>
					<div class="mySwiper wow fadeInUp">
						<swiper :modules="historySwpiermodules" :breakpoints="historySwpierOption.breakpoints"
							:scrollbar="{ draggable: true }" :pagination="{ clickable: true }" :navigation="historySwpierOption.navigation">
							<swiper-slide v-for="(item,index) in historyArray" :key="item.year">
								<div class="swpier_mid" :class="[onHoverNum===index?'isActive':'']"
									@mouseover="onHover(index)">
									<div class="swpier_mid_1">
										<span>{{item.year}}</span>
									</div>
									<div class="swpier_mid_2"> </div>
								</div>
							</swiper-slide>
						</swiper>
					</div>
				</div>
				<div class="historyDiv">
					<div class="div1200 historyDiv_main">
						<div class="historyDiv_left wow fadeInUp">
							<div class="imgDiv">
								<!-- <img src="../assets/images/about/img3.png"> -->
								<img v-if="historyObject.picture" :src="`${historyObject.picture}`">
							</div>
							<div class="detailDiv">
								<div class="p1">
									{{historyObject.year}} </div>
								<div class="p2">
									The establishment of Canhelp Genomics
								</div>
								<div class="p3">
									{{historyObject.info}}
								</div>
							</div>
						</div>
						<div class="historyDiv_right">
							<span class="btn-left" @click="prevItem"> &lt; </span>
							<span class="btn-right" @click="nextItem"> &gt; </span>
						</div>
					</div>
				</div>
			</div>
			<!-- contactUs -->
			<div class="contactUs" ref="contactUs" id="contactUs">
				<div class="div1200">
					<el-row>
						<el-col :xs="24" :sm="10">
							<div class="contactUs_title wow fadeInUp">
								contact us
							</div>
							<div class="contactUs_subtitle wow fadeInUp">
								Canhelp Genomics
							</div>
							<div class="contactUs_main">
								<div class="contactUs_main_div wow fadeInUp">
									<div class="contactUs_name">
										Address
									</div>
									<div class="contactUs_description">
										3rd Floor, No.22, Xinyan Road, Hangzhou, China
									</div>
								</div>
								<div class="contactUs_main_div wow fadeInUp">
									<div class="contactUs_name">
										Email
									</div>
									<div class="contactUs_description">
										info@canhelpgenomics.com
									</div>
								</div>
							</div>
						</el-col>
						<!-- 地图 -->
						<el-col :xs="24" :sm="14">
							<div id="container">

							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// 动画效果
	import WOW from 'wow.js'
	import 'wow.js/css/libs/animate.css'
	// import Swiper core and required modules
	import {
		Navigation,
		Pagination,
		Scrollbar,
		A11y
	} from 'swiper';

	// Import Swiper Vue.js components
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue';

	// Import Swiper styles
	import 'swiper/css';
	import 'swiper/css/navigation';
	import 'swiper/css/pagination';
	import 'swiper/css/scrollbar';

	// 高德地图
	import AMapLoader from '@amap/amap-jsapi-loader';
	/*在Vue3中使用时,需要引入Vue3中的shallowRef方法(使用shallowRef进行非深度监听,
	因为在Vue3中所使用的Proxy拦截操作会改变JSAPI原生对象,所以此处需要区别Vue2使用方式对地图对象进行非深度监听,
	否则会出现问题,建议JSAPI相关对象采用非响应式的普通对象来存储)*/
	import {
		shallowRef
	} from '@vue/reactivity';
	import {
		ref
	} from "vue";

	import iconTeam from "@/assets/images/about/marker.png";

	export default {
		components: {
			Swiper,
			SwiperSlide
		},
		data() {
			return {
				// ourHistory [Navigation]
				historySwpiermodules: [Navigation],
				historySwpierOption: {
					slidesPerView: 1,
					spaceBetween: 0,
					breakpoints: {
						640: {
							slidesPerView: 7,
							spaceBetween: 0,
						},
					},
					// navigation: {
					// 	nextEl: '.btn-right',
					// 	prevEl: '.btn-left'
					// }
				},
				year: new Date().getFullYear(),
				yearArray: [],
				onHoverNum: 0,
				historyArray:[],
				historyObject:{},
				samllNum:0,
				Maximum:0,
			}
		},
		watch: {
			// 监听路由变化
			$route(to, from) {
				var that = this;
				// 跳转指定位置
				that.toTarget();
			}
		},
		mounted() {
			var that = this;
			// 动画效果
			let wow = new WOW({
				boxClass: "wow", // animated element css class (default is wow)
				animateClass: "animated", // animation css class (default is animated)
				offset: 0, // distance to the element when triggering the animation (default is 0)
				mobile: true, // trigger animations on mobile devices (default is true)
				live: true, // act on asynchronously loaded content (default is true)
				callback: function(box) {},
				scrollContainer: null, // optional scroll container selector, otherwise use window
			});
			wow.init();
			that.initMap();
			// for (var i = 2015; i < that.year; i++) {
			// 	that.yearArray.push(i);
			// }
			that.toTarget();
			that.getHistory();//获取历史
		},
		methods: {
			// 上一个
			prevItem(){
				var that=this;
				if(that.onHoverNum>that.samllNum){
					that.onHoverNum=that.onHoverNum-1;
					that.historyObject=that.historyArray[that.onHoverNum];
				}
			},
			// 下一个
			nextItem(){
				var that=this;
				if(that.onHoverNum<that.Maximum){
					that.onHoverNum=that.onHoverNum+1;
					that.historyObject=that.historyArray[that.onHoverNum];
				}
			},
			// 接收url传参,跳转至锚点
			toTarget() {
				var that = this;
				if (that.$route.query && that.$route.query.id) {
					var id = that.$route.query.id;
					// 定时
					that.$nextTick(()=>{
						if(that.$route.name=='About'){
							if (id == '1') {
								that.$refs.aboutUs.scrollIntoView();
							} else if (id == '2') {
								that.$refs.ourHistory.scrollIntoView();
							} else if (id == '3') {
								that.$refs.contactUs.scrollIntoView();
							}
						}
					})
				}
			},
			// 地图
			initMap() {
				window._AMapSecurityConfig = {
					securityJsCode: '6afe06860ce8a5f031c7d2c082c6eeaa',
				}
				AMapLoader.load({
					key: "cb27c9c7b1cc54ad47efc74b00a345f5", // 申请好的Web端开发者Key，首次调用 load 时必填
					version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
					// resizeEnable:true,
					plugins:["AMap.ToolBar", "AMap.Scale", "AMap.MapType"], // 需要使用的的插件列表，如比例尺'AMap.Scale',"AMap.HawkEye", "AMap.Geolocation", 等
				}).then((AMap) => {
					const map = new AMap.Map("container", { //设置地图容器id
						viewMode: '2D', // 默认使用 2D 模式
						zoom: 16, //初始化地图级别
						center: [120.312632, 30.449729], //初始化地图中心点位置
						scrollWheel:false, //禁止鼠标缩放
						lang: "en" //可选值：en，zh_en, zh_cn 
					});
					// 直接在MarKer中使用即可
					const marker = new AMap.Marker({
						position: new AMap.LngLat(120.312632, 30.449729),
						title: "杭州可帮基因科技有限公司", // 鼠标滑过点标记时的文字提示
						content: `<img src="${iconTeam}" />`, // 引入上面的图片
						offset: new AMap.Pixel(-15, -15)
					})
					marker.setMap(map);
					//在地图上显示工具条方法 v1.4.15
					map.addControl(new AMap.ToolBar({ //toolBar
					    //这里可以添加自己想要的参数  ，上面有官方文档的链接
						position: 'LT', // 默认位置：LT:左上角;
						autoPosition: false, //是否自动定位  默认为false
						locate: false, //是否显示定位按钮，默认为 true
					})); // 工具条
					// 添加插件  "AMap.MouseTool" v2.0
					// AMap.plugin(["AMap.ToolBar", "AMap.Scale", "AMap.HawkEye", "AMap.Geolocation", "AMap.MapType"],
					// 	function() {
					// 		map.addControl(new AMap.ToolBar()); // 工具条控件;范围选择控件
					// 		map.addControl(new AMap.Scale()); // 显示当前地图中心的比例尺
					// 		map.addControl(new AMap.MapType()); // 实现默认图层与卫星图,实时交通图层之间切换
					// 	});
				}).catch(e => {
					console.log(e);
				})
			},
			// @mouseover 事件
			onHover(index) {
				var that = this;
				that.onHoverNum = index;
				that.historyObject=that.historyArray[that.onHoverNum];
			},
			// 接收url传参,加载相应内容
			getHistory() {
				var that = this;
				that.historyArray=[];
				that.historyObject={};
				that.$api.get("/api/companyHistory/list").then(function(res) {
					that.historyArray = res.data;
					that.historyObject=res.data[that.onHoverNum];
					that.Maximum=that.historyArray.length-1;
				}).catch(function(error) {
					console.log(error)
				})
			},
		}
	}
</script>

<style scoped lang="scss">
	@import '../assets/scss/about.scss';

	#container {
		width: 100%;
		height: 410px;
		border: 5px #e7e9ec solid;
	}
</style>