<template>
	<div id="myHome">
		<my-header></my-header>
		<!-- <router-view></router-view> -->
		<router-view v-if="!$route.meta.keepAlive"></router-view>
		<my-footer></my-footer>
	</div>
</template>

<script>
	import myHeader from '../components/header.vue';
	import myFooter from '../components/footer.vue';
	export default {
		name: 'Home',
		components: {
			"my-header": myHeader,
			"my-footer": myFooter,
		},
		data() {
			return {}
		},
		mounted() {
		},
		methods: {
		}
	}
</script>

<style scoped lang="scss">
	#myHome {
		width: 100%;
		height: 100%;

		.home_main {
			width: 100%;
			height: 100%;
		}
	}
</style>