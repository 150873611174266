<template>
	<div id="investors">
		<!-- banner -->
		<div class="bannerCss">
			<div class="banner_ico">
				<img src="../assets/images/index/img3.png">
			</div>
			<div class="com_mid">
				<div class="nybanner_1 slideUpAnimation wow fadeInUp">
					INVESTORS
				</div>
			</div>
			<div class="com_mid_img">
				<img v-if="$store.getters.getMobile" src="../assets/images/mobile/inverstors.jpg">
				<img v-else src="../assets/images/inv/banner.jpg">
			</div>
		</div>
		<div class="main">
			<div class="div1200">
				<div class="pro_1 wow fadeInUp">
					LEAD INVESTORS
				</div>
				<div class="serise wow fadeInUp">
					<div class="serise_num">
						Series A
					</div>
					<div class="serise_detail">
						<div class="serise_detail_left">
							<img src="../assets/images/inv/img1.jpg" />
						</div>
						<div class="serise_detail_right">
							<p class="p1">
								ORIZA SEED
							</p>
							<p class="p2">
								Suzhou Oriza Holdings Corporation (hereinafter referred to as “Oriza Holdings”), is an investment holding company in charge of over 100 billion Yuan. Its business covers equity investment, credit finance and equity investment services, including China’s first Equity Investment Fund of Funds, the first science and finance market .
								<br>
								In twenty years, Oriza Holdings, motivated by the mission and vision of “Leading in the equity investment, practicing in creation of science and technology, and creating continuous value”, builds an integrated system of investment and financing business, Assisted by professional investment service system, Oriza Holdings has achieved a great success in exploring financial creation, boosting science creation, guiding fund accumulation and upgrading industries through the coordination of the three businesses of capital operation, risk control and operation allocation.
								<br>
								Since 2001, Oriza Holdings has been committed to the equity investment area. There are different subordinate focuses on different investment period and area. By the end of August 2022, over 1100 deals invested by the direct investment platform; The VC FoFs invested in 150 portfolio funds, with a total scale of more than 180 billion yuan, and invested in more than 3,420 enterprises.
							</p>
							<p class="p3" @click="blankLink('http://www.oriza.com.cn/')">
								http://www.oriza.com.cn/
							</p>
						</div>
					</div>
				</div>
				<div class="serise wow fadeInUp">
					<div class="serise_num">
						Series B
					</div>
					<div class="serise_detail">
						<div class="serise_detail_left">
							<img src="../assets/images/inv/img3.jpg" />
						</div>
						<div class="serise_detail_right">
							<p class="p1">
								CICC CAPITAL
							</p>
							<p class="p2">
								CICC Capital Operation Co., Ltd. (abbreviation: CICC Capital) is a wholly-owned equity investment management subsidiary of China International Capital Corporation Limited (abbreviation: CICC), while CICC (601995.SH, 3908.HK) is China’s first China-foreign joint-venture investment bank. 
								<br>
								CICC Capital undertakes business including formulating development strategies, promoting business development, resource allocation, and risk control for private equity investment.
								<br>
								CICC Capital’s overall asset management scale exceeds 380 billion RMB , and it has a professional investment management team of more than 300 staff, covering various types of funds and various direct investment products. CICC Capital has become one of the largest and leading private equity investment management funds in China.
							</p>
							<p class="p3" @click="blankLink('https://www.cicccapital.com.cn/')">
							    https://www.cicccapital.com.cn/
							</p>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	// 动画效果
	import WOW from 'wow.js'
	import 'wow.js/css/libs/animate.css'
	export default {
		data() {
			return {}
		},
		mounted() {
			let wow = new WOW({
				boxClass: "wow", // animated element css class (default is wow)
				animateClass: "animated", // animation css class (default is animated)
				offset: 0, // distance to the element when triggering the animation (default is 0)
				mobile: true, // trigger animations on mobile devices (default is true)
				live: true, // act on asynchronously loaded content (default is true)
				callback: function(box) {
				},
				scrollContainer: null, // optional scroll container selector, otherwise use window
			});
			wow.init()
		},
		methods:{
			// 跳转外部链接
			blankLink(url){
				var that=this;
				window.open(url, '_blank');
			},
		}
	}
</script>

<style scoped lang="scss">
	@import '../assets/scss/investors.scss';
	
	@media screen and (max-width: 810px){
		.serise_detail{
		    margin: 1em !important; 
		    margin-bottom: 1.5em !important;
			.serise_detail_left{
				position: static !important;
				width: 100% !important;
			}
			.serise_detail_right{
				margin-left: 0 !important;
				margin-top: 1em !important;
			}
		}
	}
</style>