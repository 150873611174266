import {
	createStore
} from 'vuex'

export default createStore({
	state: {
		mobileFlag: false,
		videoDisplay: false,
	},
	//set方法赋值  调用时  that.$store.commit('putProject', item);
	mutations: {
		updateMobile(state, mobile) {
			state.mobileFlag = mobile;
		},
		updateVideo(state, mobile) {
			state.videoDisplay = mobile;
		}
	},
	// this.$store.getters.getVideo
	getters: {
		getMobile(state){
			return state.mobileFlag
		},
		getVideo(state){
			return state.videoDisplay
		},
	},
	actions: {},
	modules: {}
})