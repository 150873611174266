import {createRouter,createWebHistory,createWebHashHistory} from 'vue-router' //安装引入vue-router
import routes from './routes.js'
/**
 * 初始化路由 配置
 */
const router = createRouter({
	// history: createWebHashHistory(),  //带#
	history: createWebHistory(),
	routes
});

// 保证每个路由跳转时都将其跳转的路由推给百度
router.beforeEach((to, from, next) => {
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', to.fullPath])
    }
  }
  next()
})

/**
 * 导出路由
 */
export default router