<template>
	<div id="myProducts">
		<!-- banner -->
		<div class="bannerCss">
			<div class="banner_ico">
				<img src="../assets/images/index/img3.png">
			</div>
			<div class="com_mid">
				<div class="nybanner_1 slideUpAnimation wow fadeInUp">
					products
				</div>
			</div>
			<div class="com_mid_img">
				<img v-if="$store.getters.getMobile" src="../assets/images/mobile/product.jpg">
				<img v-else src="../assets/images/product/banner.jpg">
			</div>
		</div>
		<!-- Product Pipeline -->
		<div class="index_2">
			<div class="div1200">
				<div class="pro_1_1_mid_right_mid_1 wow fadeInUp">
					Product Pipeline
				</div>
				<div class="p_t_1 wow fadeInUp">
					Canhelp Genomics is building an innovative portfolio of molecular diagnostic tests across areas of
					unmet clinical needs and high value markets.
				</div>
				<div class="mckTable wow fadeInUp animated">
					<el-table :data="productData.productPipeline" border style="width: 100%" :cell-class-name="cellClass"
						:span-method="arraySpanMethod">
						<el-table-column prop="name" label="Product Name" width="150" />
						<el-table-column prop="category" label="Product Category" width="230" />
						<el-table-column prop="type" label="Cancer Type" width="230" />
						<el-table-column prop="process" label="Development" min-width="130">
							<template #default="scope">
								<el-progress class="processCss wow fadeInLeft animated" :show-text="false" :stroke-width="24"
									:percentage="scope.row.process" />
							</template>
						</el-table-column>
						<el-table-column prop="validation" label="Validation" min-width="110" />
						<el-table-column prop="registration" label="Registration" min-width="120" />
						<el-table-column prop="marketing" label="Marketing" min-width="110" />
					</el-table>
				</div>
			</div>
		</div>
		<!-- Products -->
		<div class="index_3">
			<div class="pro_1_1 wow fadeInUp">
				<div class="pro_1_1_li" v-for="(item,index) in productDetailData" :key="index">
					<el-row v-if="index%2 !=0" class="wow fadeInUp">
						<el-col :xs="24" :sm="0" class="pro_1_1_mid_left">
							<!-- <img src="../assets/images/product/img1.jpg"> -->
							<img :src="`${item.picture}`">
						</el-col>
						<el-col :xs="24" :sm="12" class="pro_1_1_mid" @click="goProductDeatil(item.id)">
							<div class="pro_1_1_mid_right_mid_1 hi">
								{{item.name}}
							</div>
							<div class="pro_1_1_mid_right_mid_2">
								{{item.info}}
							</div>
							<div class="pro_1_1_mid_right_mid_3">
								Intended Use:
							</div>
							<div class="pro_1_1_mid_right_mid_4">
								{{item.desc}}
							</div>
							<div class="pro_1_1_mid_right_mid_5">
								<p>
									<span>Discover more</span>
								</p>
							</div>
						</el-col>
						<el-col :xs="0" :sm="12" class="pro_1_1_mid_left">
							<!-- <img src="../assets/images/product/img1.jpg"> -->
							<img :src="`${item.picture}`">
						</el-col>
					</el-row>
					<el-row v-else class="wow fadeInUp">
						<el-col :xs="24" :sm="12" class="pro_1_1_mid_left">
							<!-- <img src="../assets/images/product/img1.jpg"> -->
							<img :src="`${item.picture}`">
						</el-col>
						<el-col :xs="24" :sm="12" class="pro_1_1_mid" @click="goProductDeatil(item.id)">
							<div class="pro_1_1_mid_right_mid_1 hi">
								{{item.name}}
							</div>
							<div class="pro_1_1_mid_right_mid_2">
								{{item.info}}
							</div>
							<div class="pro_1_1_mid_right_mid_3">
								Intended Use:
							</div>
							<div class="pro_1_1_mid_right_mid_4">
								{{item.desc}}
							</div>
							<div class="pro_1_1_mid_right_mid_5">
								<p>
									<span>Discover more</span>
								</p>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<!-- 分页 -->
			<div class="myPage wow fadeInUp">
				<el-pagination background :pager-count="5" layout="prev, pager, next" :page-size="pageSize" :total="total" @current-change="handleCurrentChange"/>
			</div>
		</div>
	</div>
</template>

<script>
	// 动画效果
	import WOW from 'wow.js'
	import 'wow.js/css/libs/animate.css'
	// 引入本地json文件
	import jsonData from '../assets/json/data.json';
	export default {
		data() {
			return {
				productData: jsonData,
				pageIndex:1,
				pageSize:8,
				total:0,
				productDetailData:[]
			}
		},
		mounted() {
			var that=this;
			let wow = new WOW({
				boxClass: "wow", // animated element css class (default is wow)
				animateClass: "animated", // animation css class (default is animated)
				offset: 0, // distance to the element when triggering the animation (default is 0)
				mobile: true, // trigger animations on mobile devices (default is true)
				live: true, // act on asynchronously loaded content (default is true)
				callback: function(box) {
				},
				scrollContainer: null, // optional scroll container selector, otherwise use window
			});
			wow.init();
			that.getProductsData();
		},
		methods: {
			// 分页--页码的切换
			handleCurrentChange(oldV) {
				var that = this;
				that.publicationsData = [];
				that.pageIndex = oldV;
				that.getProductsData();
			},
			// 获取数据
			getProductsData(){
				var that=this;
				var pIndex=that.pageIndex -1;
				that.$api.get("/api/product/list/"+pIndex).then(function(res) {
					that.productDetailData = res.data.data;
					that.total=res.data.totalCount;
					that.pageSize=res.data.countPerPage;
				}).catch(function(error) {})
			},
			// 去产品详情
			goProductDeatil(id){
				var that=this;
				that.$router.push({
					path: '/products_show',
					query: {
						id: id
					}
				});
			},
			//列样式
			cellClass({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				// 修改某一列样式
				if (column.property == "name") {
					return "cellName";
				}
				if (column.property == "category") {
					return "cellCategory";
				}
				if (column.property == "process") {
					return "cellProcess";
				}
				return "text-align: left;";
			},

			// 合并行
			arraySpanMethod({
				row,
				column,
				rowIndex,
				columnIndex,
			}) {
				if (columnIndex >= 3) {
					if (columnIndex == 3) {
						return [1, 4]
					} else {
						return [0, 0]
					}
				}
			},
		}
	}
</script>

<style scoped lang="scss">
	@import '../assets/scss/products.scss';

	// 进度条
	.processCss {
		:deep(.el-progress-bar) {
			border-radius: 0px !important;
			.el-progress-bar__outer {
				border-radius: 0px !important;
				background-image: url('../assets/images/product/j2.jpg') !important;
				background-position: right center;
				background-repeat: no-repeat;
				border-radius: 0 1.625em 1.625em 0 !important;
				.el-progress-bar__inner {
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					background-color: #e6eff1 !important;
					background-image:url('../assets/images/product/j.png');
					background-position: right center;
					background-repeat: no-repeat;
					border-radius: 0px !important;
				}
			}
		}

	}
	
</style>