/**
 * 判断某个原生DOM元素是否不在屏幕可见区内
 * {*} el 原生DOM元素
 */
const isElementNotInViewport = function(el) {
	let rect = el.$el.getBoundingClientRect();
	return (
		(rect.top >= (window.innerHeight || document.documentElement.clientHeight)) || (rect.bottom <= 0)
	);
};

export {
	isElementNotInViewport
};