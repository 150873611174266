<template>
	<div id="myHeader">
		<div class="com_mid divAuto">
			<div class="header_mid">
				<div class="header_mid_left">
					<!-- v-if="logoBlock" -->
					<img :class="[logoBlock?'logoBlockCss':'']" src="../assets/images/header/logo.png" />
				</div>
				<my-menu class="header_mid_right"></my-menu>
			</div>
		</div>
	</div>
</template>

<script>
	import myMenu from './menu.vue'
	export default {
		components: {
			"my-menu": myMenu
		},
		data() {
			return {
				logoBlock: false,
				screenWidth: null,
			}
		},
		watch: {
			// 监听路由变化
			$route(to, from) {
				var that = this;
				that.logoMethod(); // 首页 隐藏logo
			},
			screenWidth: {
				handler: function(val) {
					if (val < 900) {
						// console.log(val + '屏幕宽度小于900px')
					} else {
						// console.log(val + '屏幕宽度大于900px')
					}
				},
				immediate: true,
				deep: true
			},
		},
		destroyed() {
			window.removeEventListener('scroll', this.scrollHander)
		},
		// 屏幕尺寸变化
		// created() {
		// 	this.screenWidth = document.body.clientWidth;
		// 	window.onresize = () => {
		// 		return (() => {
		// 			this.screenWidth = document.body.clientWidth
		// 		})()
		// 	}
		// },
		mounted() {
			var that = this;
			that.logoMethod(); // 首页 隐藏logo
			// 滚动界面,顶部添加背景
			that.$nextTick(function() {
				window.addEventListener('scroll', that.scrollHander);
			})
		},
		methods: {
			// 首页 隐藏logo
			logoMethod() {
				var that = this;
				if (that.$route.name == 'Index') {
					that.logoBlock = false;
				} else {
					that.logoBlock = true;
				}
			},
			scrollHander() {
				var that = this;
				var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				if (scrollTop > 5) {
					$('#myHeader').addClass('cur');
					if (that.$route.name == 'Index') {
						that.logoBlock = true;
					}
				} else {
					$('#myHeader').removeClass('cur');
					if (that.$route.name == 'Index') {
						that.logoBlock = false;
					}
				}
			},
		}
	}
</script>

<style scoped lang="scss">
	#myHeader {
		padding: 1.25em 0;
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 888;
		transition-duration: 0.5s;
		font-size: 1.125em;

		.header_mid {
			// position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.header_mid_left {
				// position: absolute;
				// left: 0;
				// top: 50%;
				// transform: translate(-0%, -50%);

				img {
					transition-duration: 0.5s;
					opacity: 0;
					max-width: 13.75vw;
				}
			}

			.header_mid_right {
				margin-right: 0 !important;
				// position: absolute;
				// right: 0;
				// top: 0%;
				height: 100%;
				text-align: right !important;
			}
		}
	}

	.logoBlockCss {
		opacity: 1 !important;
	}

	// 顶部 滚动动态背景
	.cur {
		background: #00388a;
		padding: 0.875em 0 !important;
	}

	@media screen and (max-width: 950px) {
		.header_mid {
			height: 44px;

			.header_mid_left {
				img {
					max-width: 12em !important;
				}
			}
		}
	}
</style>