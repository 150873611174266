<template>
	<div id="productsShow">
		<div class="nybanner">
			<div class="index_1_ico">
				<img src="../assets/images/index/img3.png">
			</div>
			<div class="nybanner_bg">
			</div>
		</div>
		<div class="pro_1 div1200">
			<div class="pro_1_1_mid_right_mid_1 daxie inv_1_1 wow fadeInUp">
				{{productContent.name}}
			</div>
			<el-row class="pro_1_1 wow fadeInUp">
				<el-col :xs="24" :sm="12" class="pro_1_1_mid_left wow fadeInUp">
					<div class="leftContent">
						<!-- <img src="../assets/images/product/img1.jpg"> -->
						<img :src="`${picture}`">
					</div>
				</el-col>
				<el-col :xs="24" :sm="12" class="pro_1_1_mid_right wow fadeInUp">
					<div class="pro_info_1">
						{{ productContent.cover ? productContent.cover.name:""}}
					</div>
					<div class="clearfix pro_info_2">
						{{ productContent.cover ? productContent.cover.info:""}}
					</div>
					<div class="clearfix pro_info_3">
						<dl>
							<dd>
								<span>
									<img src="../assets/images/product/img4.png">
									{{ productContent.cover ? productContent.cover.pcr:""}}
								</span>
							</dd>
							<dd>
								<span>
									<img src="../assets/images/product/img5.png">
									{{ productContent.cover ? productContent.cover.software:""}}
								</span>
							</dd>
							<dd>
								<span>
									<img src="../assets/images/product/img6.png">
									{{ productContent.cover ? productContent.cover.spec:""}}
								</span>
							</dd>
						</dl>
					</div>
				</el-col>
			</el-row>
			<!-- 进度条 -->
			<el-row class="pro_2 wow fadeInUp" :gutter="25" ref="myProgress">
				<el-col :xs="12" :sm="6" v-for="(item,index) in productContent.features" :key="index+''" v-if="isFixed">
					<el-row class="pro_2_1 wow fadeInUp" v-if="item.type==1">
						<el-col :xs="8" :sm="8" class="pro_left">
							<count-up :end-val="parseInt(item.number)" />
						</el-col>
						<el-col :xs="16" :sm="16" class="pro_right">
							{{item.info}}
						</el-col>
					</el-row>
					<div class="pro_2_3 wow fadeInUp" v-if="item.type==2 && index!=(productContent.features.length-1)">
						<div class="pro_left">
							<el-progress type="circle" :percentage="item.displayNumber" color="#56AF63"
								:stroke-width="7">
								<template #default="{ percentage }">
									<span class="percentage-value">
										<span>{{parseInt(item.number)}}</span>
										<i>%</i>
									</span>
								</template>
							</el-progress>

							<!-- <el-progress type="circle" :percentage="parseInt(item.number)" color="#56AF63"
								:stroke-width="7">
								<template #default="{ percentage }">
									<span class="percentage-value">
										<count-up :end-val="percentage" />
										<i>%</i>
									</span>
								</template>
							</el-progress> -->
						</div>
						<div class="pro_right">
							{{item.info}}
						</div>
					</div>
					<div class="pro_2_4 wow fadeInUp" v-if="item.type==2 && index==productContent.features.length-1">
						<div class="pro_left">
							<el-progress type="circle" :percentage="item.displayNumber" color="#07408f"
								:stroke-width="7">
								<template #default="{ percentage }">
									<span class="percentage-value">
										<span>{{item.number}}</span>
										<i>%</i>
									</span>
								</template>
							</el-progress>

							<!-- <el-progress type="circle" :percentage="handleLg(item.number)" color="#07408f"
								:stroke-width="7">
								<template #default="{ percentage }">
									<span class="percentage-value">
										<span v-if="hasLg">&gt;</span>
										<count-up :end-val="percentage" />
										<i>%</i>
									</span>
								</template>
							</el-progress> -->
						</div>
						<div class="pro_right">
							{{item.info}}
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<!-- 背景 -->
		<div class="pro_3">
			<div class="div1200">
				<div class="pro_3_title wow fadeInUp">
					Background
				</div>
				<div class="pro_3_content wow fadeInUp">
					{{productContent.background}}
				</div>
			</div>
		</div>
		<!-- PRODUCT DESCRIPTION -->
		<div class="pro_4">
			<div class="div1200">
				<div class="main wow fadeInUp">
					<el-row :gutter="25">
						<el-col :xs="24" :sm="12" class="pro_4_img wow fadeInUp">
							<!-- <img src="../assets/images/product/img8.jpg"> -->
							<img v-if="productId%2==0" src="../assets/images/product/img8_2.jpg">
							<img v-else src="../assets/images/product/img8.jpg">
						</el-col>
						<el-col :xs="24" :sm="12" class="pro_4_mid wow fadeInUp">
							<div class="pro_4_title">
								Product Description
							</div>
							<div class="pro_4_description wow fadeInUp">
								{{productContent.description ? productContent.description.info :""}}
							</div>
							<div class="pro_4_dl wow fadeInUp">
								<dl v-if="productContent.description">
									<dd v-for="(item,index) in productContent.description.subInfo" :key="index=''">
										<span>{{item}}</span>
									</dd>
								</dl>
							</div>
							<!-- 增加一个文字链接，文字为“Sample Test Result” -->
							<div class="testResultTitle" v-if="productId==1 || productId==2" @click="testResultClick" :style="{background:(buttonClick?'#00327A':'')}">
								Sample Test Result
							</div>
							<el-dialog title="Sample Test Result" v-model="testResultVisible" width="50%">
								<div class="testResultStyle" v-if="productId==1">
									<img class="testResultBanner" src="../assets/images/product/testResult1.jpg">
									<div class="testResultText">
										The top 5 tumor types with the highest similarity score values are: breast
										cancer
										(90.3), cervix cancer (1.7), ovary cancer(1.6), germ cell tumor (1.1) and
										neuroendocrine cancer (1), thus suggesting the sample is most likely breast
										cancer.
									</div>
								</div>
								<div class="testResultStyle" v-if="productId==2">
									<img class="testResultBanner" src="../assets/images/product/testResult2.png">
									<div class="testResultText">
										<img src="../assets/images/product/testResult2-1.png">
										T risk score ≥50 is classified as positive; risk score＜50 is classified as
										negative.
									</div>
								</div>

							</el-dialog>

						</el-col>
					</el-row>
					<el-row :gutter="25">
						<el-col :xs="24" :sm="0" class="pro_4_img wow fadeInUp">
							<!-- <img src="../assets/images/product/img9.jpg"> -->
							<img v-if="productId%2==0" src="../assets/images/product/img9_2.jpg">
							<img v-else src="../assets/images/product/img9.jpg">
						</el-col>
						<el-col :xs="24" :sm="12" class="pro_4_mid">
							<div class="pro_4_title wow fadeInUp">
								INTENDED USE
							</div>
							<div class="pro_4_dl wow fadeInUp" v-if="productContent.intendUse"
								v-for="(item,index) in productContent.intendUse.items" :key="index=''">
								<div class="mytip">{{item.info}}</div>
								<dl v-if="item.subInfo && item.subInfo.length>0">
									<dd v-for="(subitem,subindex) in item.subInfo" :key="subindex=''">
										<span>{{subitem}}</span>
									</dd>
								</dl>
							</div>
						</el-col>
						<el-col :xs="0" :sm="12" class="pro_4_img wow fadeInUp">
							<!-- <img src="../assets/images/product/img9.jpg"> -->
							<img v-if="productId%2==0" src="../assets/images/product/img9_2.jpg">
							<img v-else src="../assets/images/product/img9.jpg">
						</el-col>
						<el-col :xs="24" :sm="24" class="pro_4_bottom wow fadeInUp">
							{{productContent.intendUse ? productContent.intendUse.tip :""}}
						</el-col>
					</el-row>
					<el-row :gutter="25">
						<el-col :xs="24" :sm="12" class="pro_4_img wow fadeInUp">
							<!-- <img src="../assets/images/product/img10.jpg"> -->
							<img v-if="productId%2==0" src="../assets/images/product/img10_2.jpg">
							<img v-else src="../assets/images/product/img10.jpg">
						</el-col>
						<el-col :xs="24" :sm="12" class="pro_4_mid wow fadeInUp">
							<div class="pro_4_title">
								SAMPLE REQUIREMENT
							</div>
							<div class="pro_4_dl wow fadeInUp">
								<dl v-if="productContent.requirement">
									<dd v-for="(item,index) in productContent.requirement" :key="index=''">
										<span>{{item}}</span>
									</dd>
								</dl>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
		<!-- Certificate -->
		<div class="pro_5" v-if="productContent.certificate && productContent.certificate.length>0">
			<div class="div1200">
				<div class="pro_5_title wow fadeInUp">
					Certificate
				</div>
				<div class="pro_5_con">
					<div class="pro_5_main wow fadeInUp" v-for="(item,index) in productContent.certificate"
						:key="index+''">
						<div class="pro_5_img" v-if="item=='NMPA'">
							<img src="../assets/images/product/img12.png">
						</div>
						<div class="pro_5_img" v-if="item=='CE'">
							<img src="../assets/images/product/img13.png">
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- COMPATIBLE -->
		<div class="pro_6">
			<div class="div1200">
				<div class="pro_6_title wow fadeInUp">
					COMPATIBLE INSTRUMENT
				</div>
				<div class="pro_6_main">
					<div class="pro_6_div wow fadeInUp" v-for="(item,index) in productContent.instrument"
						:key="index+''">
						<div class="pro_6_img">
							<img :src="`${item.picture}`">
						</div>
						<div class="pro_6_subtitle">
							<div>&nbsp;</div>
							<span>{{item.title}}</span>
						</div>
					</div>
					<!-- <div class="pro_6_div  wow fadeInUp">
						<div class="pro_6_img">
							<img src="../assets/images/product/img14.jpg">
						</div>
						<div class="pro_6_subtitle">
							<span>ABI  7500</span>
						</div>
					</div> -->
				</div>
			</div>
		</div>
		<!-- Other information -->
		<div class="pro_7">
			<div class="div1200">
				<div class="pro_7_title wow fadeInUp">
					OTHER INFORMATION
				</div>
				<div class="pro_7_main wow fadeInUp">
					<el-row>
						<el-col :xs="24" :sm="12" :md="8" :lg="6" class="col wow fadeInUp">
							<div class="pro_7_left">
								<span>
									<img src="../assets/images/product/img15.png">
								</span>
							</div>
							<div class="pro_7_right">
								<div class="pro_7_t">
									Storage Temp.
								</div>
								<div class="pro_7_subt">
									{{productContent.storage}}
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="12" :md="8" :lg="6" class="col wow fadeInUp">
							<div class="pro_7_left">
								<span>
									<img src="../assets/images/product/img16.png">
								</span>
							</div>
							<div class="pro_7_right">
								<div class="pro_7_t">
									Transport Temp.
								</div>
								<div class="pro_7_subt">
									{{productContent.transport}}
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// 动画效果
	import {
		all
	} from 'axios';
	import WOW from 'wow.js'
	import 'wow.js/css/libs/animate.css';
	import countUp from '../components/countup.vue';
	import {
		isElementNotInViewport
	} from "../utils/index.js";

	export default {
		name: "ProductsShow",
		components: {
			'count-up': countUp
		},
		data() {
			return {
				isFixed: false,
				productContent: {},
				hasLg: false,
				timer: null,
				flag: true,
				productId: "",
				picture: "",
				testResultVisible: false,
				buttonClick:false,
			}
		},
		watch: {
			// 监听路由变化
			$route(to, from) {
				var that = this;
				window.scrollTo(0, 0);
				// 获取详情
				if (to.query && to.query.id) {
					that.productId = to.query.id;
					that.getDetail(to.query.id)
				}
				// console.log(that.productId)
				$(".nybanner_bg").removeClass('on');
				that.isFixed = false;
				setTimeout(function() {
					$(".nybanner_bg").addClass('on');
					// 元素是否在可视化区域
					window.addEventListener("scroll", () => {
						if (to.name == 'ProductsShow' && !that.isFixed) {
							that.myscroll();
						}
					});
				}, 10)
			}
		},
		mounted() {
			var that = this;
			let wow = new WOW({
				boxClass: "wow", // animated element css class (default is wow)
				animateClass: "animated", // animation css class (default is animated)
				offset: 0, // distance to the element when triggering the animation (default is 0)
				mobile: true, // trigger animations on mobile devices (default is true)
				live: true, // act on asynchronously loaded content (default is true)
				callback: function(box) {},
				scrollContainer: null, // optional scroll container selector, otherwise use window
			});
			wow.init();
			if (that.$route.query && that.$route.query.id) {
				that.productId = that.$route.query.id;
				that.getDetail(that.$route.query.id)
			}
			// console.log(that.productId)
			$(".nybanner_bg").removeClass('on');
			setTimeout(function() {
				$(".nybanner_bg").addClass('on');
				// 元素是否在可视化区域
				window.addEventListener("scroll", () => {
					if (that.$route.name == 'ProductsShow' && !that.isFixed) {
						that.myscroll();
					}
				});
			}, 10)

		},
		// destoryed() {
		// 	this.clearInterval();
		// },
		methods: {
			// 点击打开图片
			testResultClick() {
				var that = this;
				that.testResultVisible = true;
				that.buttonClick=true;
				setTimeout(function(){
					that.buttonClick=false;
				},100)
			},
			// 环形进度动画
			percentageValue(val) {
				var that = this;
				var percentage = 0;
				that.timer = null;
				clearInterval(that.timer);
				that.timer = setInterval(function() {
					if (percentage < val) {
						percentage = percentage + 1;
					} else {
						that.timer = null;
						clearInterval(that.timer);
					}
					console.log(percentage)
					return percentage;
				}, 10);
			},
			// 处理大于号
			handleLg(val) {
				var that = this;
				var index = val.indexOf('>');
				var nummber = "";
				if (index > -1) {
					var percentArr = val.split('>');
					that.hasLg = true;
					nummber = parseInt(percentArr[1]);
				} else {
					that.hasLg = false;
					nummber = parseInt(val);
				}
				return nummber;
			},
			// 进度条
			myscroll() {
				var that = this;
				if (that.$refs.myProgress) {
					if (isElementNotInViewport(that.$refs.myProgress)) {
						// that.isFixed = false;
					} else {
						that.isFixed = true;
						var timer2 = setInterval(function() {
							if (that.productContent.features && that.productContent.features.length > 0) {
								clearInterval(timer2)
								that.productContent.features.forEach(item => {
									item.displayNumber = 0;
									item.clicker = setInterval(function() {
										var currentNumber = that.handleLg(item.number);
										if (item.displayNumber < currentNumber) {
											item.displayNumber++;
										} else {
											clearInterval(item.clicker);
										}
									}, 10);
								})
							}
						}, 10)
					}
				}
			},
			// 接收url传参,加载相应内容
			getDetail(id) {
				var that = this;
				that.productContent = {};
				that.picture = "";
				that.$api.get("/api/product/detail/" + id).then(function(res) {
					that.productContent = JSON.parse(JSON.stringify(res.data));
					that.picture = that.productContent.cover.picture;
				}).catch(function(error) {
					console.log(error)
				})
			},
		}
	}
</script>

<style scoped lang="scss">
	@import '../assets/scss/products_show.scss';
</style>