import Home from "@/pages/home.vue" //入口
import Index from "@/pages/index.vue" //首页
import Products from "@/pages/products.vue" //products
import ProductsShow from "@/pages/products_show.vue" //product详情
import Publications from "@/pages/publications.vue" //publications
import News from "@/pages/news.vue" //news
import NewsShow from "@/pages/news_show.vue" //news详情
import Investors from "@/pages/investors.vue" //投资者
import Advisors from "@/pages/advisors.vue" //投资者
import About from "@/pages/about.vue" //关于
import Privacy from "@/pages/privacy.vue" //隐私政策

const routes = [{
		path: '/',
		name: 'Home',
		component: Home,
		redirect: '/index',
		children: [{
			path: '/index',
			name: 'Index',
			component: Index,
			meta: {
				menuId: '1',
				menuName: 'Home',
			}
		}, {
			path: '/products',
			name: 'Products',
			component: Products,
			meta: {
				menuId: '2',
				menuName: 'Products',
				// childrenArray: ['Canhelp-Origin','Canhelp-UriBLAD','Canhelp-Kidney','Canhelp-Lung','Canhelp-MB']
				// childrenArray: [{
				// 	menuId: '2_1',
				// 	menuName: 'Canhelp-Origin',
				// }, {
				// 	menuId: '2_2',
				// 	menuName: 'Canhelp-UriBLAD',
				// }, {
				// 	menuId: '2_3',
				// 	menuName: 'Canhelp-Kidney',
				// }, {
				// 	menuId: '2_4',
				// 	menuName: 'Canhelp-Lung',
				// }, {
				// 	menuId: '2_5',
				// 	menuName: 'Canhelp-MB',
				// }]
				childrenArray:[]
			},
		}, {
			path: '/products_show',
			name: 'ProductsShow',
			component: ProductsShow,
			meta: {
				menuId: '3',
				menuName: 'ProductsShow',
				hide: true
			}
		}, {
			path: '/publications',
			name: 'Publications',
			component: Publications,
			meta: {
				menuId: '4',
				menuName: 'Publications',
			}
		}, {
			path: '/news',
			name: 'News',
			component: News,
			meta: {
				menuId: '5',
				menuName: 'News',
			}
		}, {
			path: '/news_show',
			name: 'NewsShow',
			component: NewsShow,
			meta: {
				menuId: '6',
				menuName: 'NewsShow',
				hide: true
			}
		}, {
			path: '/advisors',
			name: 'Advisors',
			component: Advisors,
			meta: {
				menuId: '10',
				menuName: 'Advisors',
			}
		}, {
			path: '/investors',
			name: 'Investors',
			component: Investors,
			meta: {
				menuId: '7',
				menuName: 'Investors',
			}
		}, {
			path: '/about',
			name: 'About',
			component: About,
			meta: {
				menuId: '8',
				menuName: 'About',
				// childrenArray:['About Us','Our History','Contact Us']
				childrenArray: [{
					menuId: '8_1',
					menuName: 'About Us',
				},{
					menuId: '8_2',
					menuName: 'Our History',
				},{
					menuId: '8_3',
					menuName: 'Contact Us',
				}]
			}
		}, {
			path: '/privacy',
			name: 'Privacy',
			component: Privacy,
			meta: {
				menuId: '9',
				menuName: 'Privacy',
				hide: true
			}
		}]
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/index"
	}
]

export default routes