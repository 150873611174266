<template>
	<span ref="count" />
</template>

<script>
	import {
		CountUp
	} from 'countup.js'
	const defaultOptions = {
		startVal: 0, // number to start at (0)
		decimalPlaces: 0, // number of decimal places (0)
		duration: 2, // animation duration in seconds (2)
		useGrouping: true, // example: 1,000 vs 1000 (true)
		useEasing: true, // ease animation (true)
		smartEasingThreshold: 999, // smooth easing for large numbers above this if useEasing (999)
		smartEasingAmount: 333, // amount to be eased for numbers above threshold (333)
		separator: ',', // grouping separator (',')
		decimal: '.' ,// decimal ('.')
	}
	export default {
		name: 'CountUp',
		props: {
			// 起始值
			endVal: {
				type: Number,
				default: 0
			},
			options: {
				type: Object,
				default: () => {}
			}
		},
		watch: {
			endVal() {
				if (this.$refs.count) {
					this.initCountUp()
				}
			}
		},
		mounted() {
			this.initCountUp()
		},
		methods: {
			initCountUp() {
				const coutOptions = Object.assign({}, defaultOptions, this.options)
				const countUp = new CountUp(this.$refs.count, this.endVal, coutOptions)
				countUp.start()
			}
		}
	}
</script>